import { Button } from "./base/Button";
import { Text } from "./base/Text";
import { View } from "./base/View";
import { Icon } from "./base/Icon";
import { ExtendedVoice, ExtendedPublicVoice } from "../types/voice";
import { Tooltip } from "./base/Tooltip";
import { useAppDispatch } from "../redux";
import { voiceActions } from "../redux/reducers/voiceSlice";
import clsx from "clsx";

type VoiceCardProps = {
  voice: ExtendedVoice | ExtendedPublicVoice;
  index: number;
  onClickPlay: () => void;
  onClickCreate: () => void;
  isPlaying: boolean;
};

const gradients = [
  "linear-gradient(45deg, #7C3AED, #C084FC)", // Purple gradient
  "linear-gradient(45deg, #2563EB, #60A5FA)", // Blue gradient
  "linear-gradient(45deg, #059669, #34D399)", // Green gradient
  "linear-gradient(45deg, #DC2626, #FB7185)", // Red gradient
  "linear-gradient(45deg, #D97706, #FBBF24)", // Yellow gradient
  "linear-gradient(45deg, #0891B2, #22D3EE)", // Cyan gradient
  "linear-gradient(45deg, #7C3AED, #F472B6)", // Purple-Pink gradient
  "linear-gradient(45deg, #4F46E5, #818CF8)", // Indigo gradient
];

const Badge = ({ children, color }: { children: React.ReactNode; color: string }) => (
  <View
    className={clsx(
      "px-2 py-0.5 rounded-full",
      `bg-${color}-100 dark:bg-${color}-900/20`,
      `border border-${color}-200 dark:border-${color}-700/30`,
      `text-${color}-700 dark:text-${color}-300`,
    )}
  >
    <Text className="text-xs font-medium">{children}</Text>
  </View>
);

export const VoiceCard = ({ voice, index, onClickCreate, onClickPlay, isPlaying }: VoiceCardProps) => {
  const dispatch = useAppDispatch();
  const gradient = gradients[index % gradients.length];

  const getVoiceTypeBadge = () => {
    if (voice.type === "base") {
      return <Badge color="blue">Base</Badge>;
    } else if (voice.isOwner) {
      return <Badge color="purple">My Voice</Badge>;
    }
    return null;
  };

  const onDeleteHandler = async () => {
    try {
      await dispatch(voiceActions.deleteById(voice.id)).unwrap();
    } catch (error) {
      console.error("Failed to delete voice:", error);
    }
  };

  const onPublishHandler = async () => {
    try {
      await dispatch(
        voiceActions.updateById({
          id: voice.id,
          isPublished: true,
        }),
      ).unwrap();
    } catch (error) {
      console.error("Failed to publish voice:", error);
    }
  };

  return (
    <View
      className={clsx(
        "w-full h-full rounded-lg bg-white dark:bg-gray-800",
        "border border-gray-200 dark:border-gray-700",
        "hover:border-brand dark:hover:border-brand-light",
        "transition-all duration-200 shadow-sm hover:shadow-md",
        "flex flex-col",
      )}
    >
      <View className="p-4 flex flex-col h-full">
        {/* Header with avatar and title */}
        <View className="flex items-center space-x-3">
          <View
            className="w-10 h-10 rounded-full flex-shrink-0 shadow-md"
            style={{ background: gradient }}
          />
          <View className="flex-1 min-w-0">
            <Text
              variant="body"
              className="font-semibold text-lg truncate"
            >
              {voice.name}
            </Text>
            <View className="mt-1">{getVoiceTypeBadge()}</View>
          </View>
        </View>

        {/* Description */}
        {voice.description && (
          <Text
            variant="muted"
            className="mt-3 line-clamp-3 text-sm"
          >
            {voice.description}
          </Text>
        )}

        {/* Voice Attributes */}
        {(voice.gender || voice.accent || voice.pitch) && (
          <View className="mt-3 flex flex-wrap gap-2">
            {voice.gender && <Badge color="gray">{voice.gender}</Badge>}
            {voice.accent && <Badge color="gray">{voice.accent}</Badge>}
            {voice.pitch && <Badge color="gray">{voice.pitch}</Badge>}
          </View>
        )}

        {/* Tags */}
        {voice.tags && (
          <View className="mt-3 flex flex-wrap gap-1.5">
            {voice.tags.split(",").map((tag: string) => (
              <Badge
                key={tag}
                color="brand"
              >
                {tag.trim()}
              </Badge>
            ))}
          </View>
        )}
      </View>

      {/* Actions Footer */}
      <View className="flex flex-row items-center gap-2 mt-auto p-4 justify-between border-t border-gray-200 dark:border-gray-600">
        {/* Community Icon */}
        {!voice.isOwner && voice.type !== "base" && (
          <Tooltip content="This voice is a community contribution">
            <View className="flex items-center justify-center text-gray-500">
              <Icon
                name="community"
                size={20}
              />
            </View>
          </Tooltip>
        )}

        {/* Right-aligned actions */}
        <View className="flex flex-row items-center gap-2">
          {voice.isOwner && !voice.isPublished && (
            <Tooltip content="Publish to Community">
              <Button
                type="basic"
                variant="ghost"
                onClick={onPublishHandler}
                className="!w-8 !h-8 !rounded-full flex items-center justify-center text-gray-500 hover:text-brand hover:bg-brand/10 !bg-transparent"
              >
                <Icon
                  name="share"
                  size={16}
                />
              </Button>
            </Tooltip>
          )}
          {voice.isOwner && (
            <Tooltip content="Delete Voice">
              <Button
                type="basic"
                variant="ghost"
                onClick={onDeleteHandler}
                className="!w-8 !h-8 !rounded-full flex items-center justify-center text-red-500 hover:text-red-600 hover:bg-red-50 dark:hover:bg-red-900/20 !bg-transparent"
              >
                <Icon
                  name="trash"
                  size={16}
                />
              </Button>
            </Tooltip>
          )}
          <Button
            type="basic"
            variant={isPlaying ? "default" : "ghost"}
            onClick={onClickPlay}
            className={clsx(
              "!w-8 !h-8 !rounded-full flex items-center justify-center",
              isPlaying ? "!bg-brand dark:!bg-brand-light !text-white" : "hover:bg-brand/10",
            )}
          >
            <Icon
              name={isPlaying ? "pause" : "play-arrow"}
              size={18}
            />
          </Button>
          {!voice.isOwner && (
            <Tooltip content="Create with this voice">
              <Button
                type="basic"
                variant="ghost"
                onClick={onClickCreate}
                className="!w-8 !h-8 !rounded-full flex items-center justify-center hover:bg-brand/10"
              >
                <Icon
                  name="add"
                  size={16}
                />
              </Button>
            </Tooltip>
          )}
        </View>
      </View>
    </View>
  );
};
