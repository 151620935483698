import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createRestSlice, Item, ItemState } from "./restSlice";

export interface TrainingJob extends Item {
  jobName: string;
  status: "queued" | "running" | "paused" | "completed" | "failed";
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  completedAt?: string;
  epoch: number;
  latestCheckpoint?: string;
}

const baseSlice = createRestSlice<TrainingJob>("trainingJob", "/v1/trainingJobs", {
  extraReducers: (builder) => {
    builder.addCase(
      "trainingJob/updateProgress",
      (
        state: ItemState<TrainingJob>,
        action: PayloadAction<{
          id: string;
          epoch: number;
          status: TrainingJob["status"];
          latestCheckpoint?: string;
        }>,
      ) => {
        const job = state.entities[action.payload.id];
        if (job) {
          Object.assign(job, action.payload);
          job.updatedAt = new Date().toISOString();
          if (["completed", "failed"].includes(action.payload.status)) {
            job.completedAt = new Date().toISOString();
          }
        }
      },
    );
  },
});

export const trainingJobReducer = baseSlice.reducer;
export const trainingJobActions = {
  ...baseSlice.actions,
  updateProgress: (payload: { id: string; epoch: number; status: TrainingJob["status"]; latestCheckpoint?: string }) => ({
    type: "trainingJob/updateProgress",
    payload,
  }),
};
