import React from "react";
import { View } from "../base/View";
import { Text } from "../base/Text";
import { Card } from "../base/Card";

type AuthLayoutProps = {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  footer?: React.ReactNode;
};

export const AuthLayout = ({ children, title, subtitle, footer }: AuthLayoutProps) => {
  return (
    <View className="w-full min-h-screen flex items-center justify-center p-4">
      <View className="w-full max-w-md space-y-8">
        {/* Header */}
        <View className="space-y-6">
          <View className="flex flex-col">
            <Text
              variant="brand"
              className="text-4xl sm:text-5xl font-bold leading-tight text-center mb-3 block"
            >
              {title}
            </Text>
            <Text
              variant="muted"
              className="text-lg text-center block"
            >
              {subtitle}
            </Text>
          </View>
        </View>

        {/* Main Content */}
        <Card className="p-6 space-y-6">{children}</Card>

        {/* Footer */}
        {footer && <View className="flex flex-col items-center space-y-4">{footer}</View>}
      </View>
    </View>
  );
};
