import React from "react";
import { Button } from "../base/Button";
import { Text } from "../base/Text";
import { View } from "../base/View";

type BalanceCardProps = {
  balance: number;
  onClickAddFunds: () => void;
  className?: string;
};

export const BalanceCard = ({ balance, onClickAddFunds, className }: BalanceCardProps) => {
  return (
    <View className={`bg-white dark:bg-gray-800 rounded-lg p-6 border border-gray-200 dark:border-gray-700 shadow-sm ${className}`}>
      <Text
        variant="brand"
        className="text-xl font-semibold mb-4"
      >
        Balance
      </Text>
      <View className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <Text
          variant="body"
          className="text-3xl font-bold mb-4 sm:mb-0"
        >
          ${(balance / 1000000).toFixed(2)} available
        </Text>
        <Button
          onClick={onClickAddFunds}
          variant="default"
          className="w-full sm:w-auto"
        >
          Add Funds
        </Button>
      </View>
    </View>
  );
};
