import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom";

import { Button } from "../components/base/Button";
import { Generation, generationActions } from "../redux/reducers/generationSlice";
import { Icon } from "../components/base/Icon";
import { InputField } from "../components/base/InputField";
import { publicVoiceActions } from "../redux/reducers/publicVoiceSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { View } from "../components/base/View";
import { Voice, voiceActions } from "../redux/reducers/voiceSlice";
import { SearchField } from "../components/base/SearchField";
import { FieldLabel } from "../components/base/FieldLabel";
import { ModelSelector } from "../components/ModelSelector";
import { AddVoiceDialog } from "../components/AddVoiceDialog";
import { Switch } from "../components/base/Switch";
import { AudioPlayer } from "../components/AudioPlayer";
import { fileActions } from "../redux/reducers/fileSlice";
import { Dialog } from "../components/base/Dialog";
import { useNavigation } from "../hooks/useNavigation";
import { TutorialOverlay } from "../components/TutorialOverlay";
import { Tooltip } from "../components/base/Tooltip";
import { useSearchParam } from "../hooks/useSearchParam";
import { GenerationList } from "../components/profile/GenerationList";
import { SuggestionBubbles } from "../components/base/SuggestionBubbles";

type GenerationWithModel = Generation & {
  voiceName: string;
  model: string;
};

export const CreatePage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const voiceId = useSearchParam("voiceId");
  const text = useSearchParam("text");
  const notes = useSearchParam("notes");
  const isLoadingGeneration = useAppSelector((state) => state.generation.isLoadingCreate);
  const voices = Object.values(useAppSelector((state) => state.voice.entities));
  const publicVoices = Object.values(useAppSelector((state) => state.publicVoice.entities));
  const generations = Object.values(useAppSelector((state) => state.generation.entities));
  const combinedVoices = [...voices, ...publicVoices].sort((a, b) => a.name.localeCompare(b.name)) as Voice[];
  const [generationPreview, setGenerationPreview] = useState<Generation | null>(null);
  const [formData, setFormData] = useState<Partial<Generation>>({
    quality: "high",
    voiceId,
    text: text || "",
    notes: notes || "",
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showAddVoiceDialog, setShowAddVoiceDialog] = useState(false);
  const [showInsufficientBalanceDialog, setShowInsufficientBalanceDialog] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  const [searchParams] = useSearchParams();
  const [isDragging, setIsDragging] = useState(false);
  const [isPlayingGenerationId, setIsPlayingGenerationId] = useState<string | null>(null);
  const [showGenerationList, setShowGenerationList] = useState(true);

  const voiceSelectionRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef<HTMLDivElement>(null);
  const convertButtonRef = useRef<HTMLButtonElement>(null);
  const directorsNotesRef = useRef<HTMLDivElement>(null);
  const directableRef = useRef<HTMLDivElement>(null);
  const basicRef = useRef<HTMLDivElement>(null);
  const voiceConversionRef = useRef<HTMLDivElement>(null);

  const tutorialSteps = [
    {
      targetRef: directableRef,
      title: "Directable Speech",
      message:
        "Studio-1.0 is our most advanced model. It takes direction and is perfect for professional audiobooks and high-end productions with emotion control.",
    },
    {
      targetRef: basicRef,
      title: "Basic Speech",
      message: "Flash-1.0 is our basic model. It's fast and cost-effective, perfect for quick conversions.",
    },
    {
      targetRef: voiceConversionRef,
      title: "Voice Conversion",
      message: "VC-1.0 lets you transform existing speech into new voices - perfect for getting the voice to sound just how you want it.",
    },
    {
      targetRef: voiceSelectionRef,
      title: "Select Voice",
      message: "Pick from our collection of AI voices or add your own voice to the mix.",
    },
    {
      targetRef: textInputRef,
      title: "Enter Text",
      message: "Enter the text you want to convert to speech. Our AI will generate natural-sounding audio from your input.",
    },
    {
      targetRef: directorsNotesRef,
      title: "Director's Notes",
      message: "Add specific instructions for how the text should be read. Control emotion, tone, and pacing to get the perfect delivery.",
    },
    {
      targetRef: convertButtonRef,
      title: "Generate Audio",
      message: "Click Convert to Speech and you are done. The result will generate quickly can be previewed or downloaded.",
    },
  ];

  const validateForm = () => {
    if (formData.quality === "voice") {
      return formData.voiceId && formData.sourceUrl;
    }

    if (formData.quality === "high") {
      return formData.voiceId && formData.text && formData.notes;
    }

    return formData.voiceId && formData.text;
  };

  const onClickAddVoice = () => {
    setShowAddVoiceDialog(true);
  };

  const onClickSubmit = async () => {
    setGenerationPreview(null);

    try {
      const response = await dispatch(generationActions.create(formData)).unwrap();

      if (response) {
        setGenerationPreview(response);
      }
    } catch (error: any) {
      if (error?.message?.includes("Insufficient balance")) {
        setShowInsufficientBalanceDialog(true);
      }
    }
  };

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);

      const response = await dispatch(fileActions.uploadFile({ type: "audio", file: e.target.files[0] })).unwrap();

      setFormData({ ...formData, sourceUrl: response });
    }
  };

  const onCloseVoiceDialog = (newVoiceId?: string) => {
    setShowAddVoiceDialog(false);

    if (newVoiceId) {
      setFormData({ ...formData, voiceId: newVoiceId });
    }
  };

  const onClickGoToProfile = () => {
    navigation.navigate("/profile");
    setShowInsufficientBalanceDialog(false);
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith("audio/")) {
        setSelectedFile(file);
        dispatch(fileActions.uploadFile({ type: "audio", file }));
      }
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    setFormData({ ...formData, sourceUrl: undefined });
  };

  const onClickPlayGenerationHandler = (generationId: string, audioUrl: string) => {
    setIsPlayingGenerationId(isPlayingGenerationId === generationId ? null : generationId);
  };

  const onClickDownloadHandler = (audioUrl: string) => {
    window.open(audioUrl, "_blank");
  };

  useEffect(() => {
    dispatch(publicVoiceActions.get({}));
    dispatch(voiceActions.get({}));
    dispatch(generationActions.get({}));
  }, []);

  useEffect(() => {
    const isTutorial = searchParams.get("tutorial") === "true";
    setShowTutorial(isTutorial);
    if (isTutorial) {
      localStorage.setItem("hasSeenTutorial", "true");
      setCurrentTutorialStep(0);
      setFormData((prev) => ({ ...prev, quality: "high" }));
    }
  }, [searchParams]);

  useEffect(() => {
    const hasSeenTutorial = localStorage.getItem("hasSeenTutorial");
    if (hasSeenTutorial !== "true") {
      navigation.navigate("/create", { tutorial: "true" });
    }
  }, []);

  return (
    <View className="w-screen h-[calc(100vh-4.5rem)] flex flex-col md:flex-row relative z-30 overflow-hidden">
      <ModelSelector
        directableRef={directableRef}
        basicRef={basicRef}
        voiceConversionRef={voiceConversionRef}
        onChange={(value) => setFormData({ ...formData, quality: value })}
        activeMode={formData.quality}
      />
      <View className="flex-1 h-full p-8 flex flex-col overflow-hidden">
        <View className="flex-grow w-full max-w-4xl mx-auto flex flex-col overflow-hidden">
          <ModelSelector
            directableRef={directableRef}
            basicRef={basicRef}
            voiceConversionRef={voiceConversionRef}
            onChange={(value) => setFormData({ ...formData, quality: value })}
            activeMode={formData.quality}
            isMobile
          />
          <View className="flex-1 flex flex-col gap-4 bg-white dark:bg-slate-800 bg-opacity-95 dark:bg-opacity-50 backdrop-blur-lg rounded-xl p-6 border border-gray-200/50 dark:border-purple-500/30 shadow-lg overflow-hidden relative z-0">
            <View
              ref={voiceSelectionRef}
              className="flex"
            >
              <SearchField
                name="voiceId"
                label="Select Voice"
                options={combinedVoices.map((v) => ({ value: v.id, label: v.name, previewUrl: v.previewUrl })) || []}
                placeholder="Choose a voice"
                className="flex-1 flex-grow"
                onClickAddOption={onClickAddVoice}
                onChange={(value) => setFormData({ ...formData, voiceId: value })}
                value={formData.voiceId}
                showAddOption
              />
            </View>
            {formData.quality === "voice" ? (
              <FieldLabel
                label="Upload Audio File"
                className="flex flex-1 flex-grow"
              >
                <View
                  className={clsx(
                    "cursor-pointer w-full h-48 border border-dashed rounded-lg flex items-center justify-center backdrop-blur-lg",
                    "flex-grow transition-colors duration-200 relative border-gray-300 dark:border-gray-700 hover:border-brand/50 dark:hover:border-brand/50",
                    "bg-white dark:bg-gray-800",
                  )}
                  onDragEnter={handleDragIn}
                  onDragLeave={handleDragOut}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  {selectedFile && (
                    <Button
                      onClick={clearSelectedFile}
                      type="basic"
                      variant="ghost"
                      className="!absolute !p-2 top-2 right-2 text-gray-400 hover:text-brand dark:text-gray-500 dark:hover:text-brand-light transition-colors"
                    >
                      <Icon
                        name="x"
                        size={20}
                      />
                    </Button>
                  )}
                  <input
                    type="file"
                    accept="audio/*"
                    onChange={onFileChange}
                    className="hidden"
                    id="audio-upload"
                  />
                  <label
                    htmlFor="audio-upload"
                    className="cursor-pointer flex flex-col items-center"
                  >
                    <Icon
                      name="upload"
                      size={48}
                      className="text-brand dark:text-brand-light mb-4"
                    />
                    <Text className="p-2 text-gray-400 dark:text-gray-500 text-center">
                      {selectedFile ? selectedFile.name : isDragging ? "Drop audio file here" : "Click to upload or drag and drop audio file"}
                    </Text>
                  </label>
                </View>
              </FieldLabel>
            ) : (
              <View className="flex flex-col flex-grow gap-4 min-h-0">
                <View
                  ref={textInputRef}
                  className="flex-grow min-h-0 max-h-[calc(100%-180px)] overflow-hidden"
                >
                  <InputField
                    name="text"
                    label="Your Text"
                    placeholder="Enter the text you want to convert to speech..."
                    type="textarea"
                    className="bg-white dark:bg-gray-800 bg-opacity-90 w-full h-full overflow-y-auto"
                    labelClassName="w-full flex flex-col h-full"
                    onChange={(value) => {
                      if (value.length <= 5000) {
                        setFormData({ ...formData, text: value });
                      }
                    }}
                    value={formData.text}
                    suffix={<Text className="text-gray-400 dark:text-gray-500 text-sm">{`${formData.text?.length || 0}/5000`}</Text>}
                    autoGrow={false}
                  />
                </View>
                {formData.quality === "high" && (
                  <View
                    ref={directorsNotesRef}
                    className="w-full"
                  >
                    <View className="flex flex-row items-center gap-2 mb-1">
                      <Text className="text-gray-700 dark:text-white text-sm font-medium">Director's Notes</Text>
                      <Tooltip content="Our Studio model is not intended for use with sexual or graphic content. It will reject these requests. It will also reject requests for singing.">
                        <Button
                          type="basic"
                          variant="ghost"
                          className="!p-1 !bg-transparent hover:!bg-transparent text-gray-400 hover:text-gray-600 dark:hover:text-white transition-colors"
                        >
                          <Icon
                            name="info"
                            size={16}
                          />
                        </Button>
                      </Tooltip>
                    </View>
                    <InputField
                      name="notes"
                      placeholder="Enter any additional notes how the text should be read..."
                      type="textarea"
                      className="bg-white dark:bg-gray-800 bg-opacity-90 w-full h-[120px]"
                      labelClassName="w-full flex flex-col"
                      onChange={(value) => {
                        if (value.length <= 250) {
                          setFormData({ ...formData, notes: value });
                        }
                      }}
                      value={formData.notes}
                      suffix={<Text className="text-gray-400 dark:text-gray-500 text-sm">{`${formData.notes?.length || 0}/250`}</Text>}
                    />
                    <SuggestionBubbles
                      onSelect={(description) => {
                        if (description.length <= 250) {
                          setFormData({ ...formData, notes: description });
                        }
                      }}
                      className="mt-2"
                    />
                  </View>
                )}
              </View>
            )}
          </View>

          <View className="mt-8 flex items-center justify-center">
            <View className="w-full max-w-4xl flex flex-col-reverse md:flex-row items-center justify-center gap-4">
              <AudioPlayer audioUrl={generationPreview?.audioUrl} />
              <Button
                ref={convertButtonRef}
                onClick={onClickSubmit}
                isDisabled={!validateForm()}
                variant="default"
                className={generationPreview ? "w-full md:w-1/3 md:translate-x-0" : "w-full md:w-1/2 md:-translate-x-2/3"}
                isLoading={isLoadingGeneration}
              >
                {formData.quality === "voice" ? "Change Voice" : "Convert to Speech"}
              </Button>
            </View>
          </View>
        </View>
      </View>

      {/* Collapsible Generation List Panel */}
      <View
        className={clsx(
          "hidden md:block h-full border-l border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 transition-all duration-300 overflow-hidden",
          showGenerationList ? "w-96" : "w-12",
        )}
      >
        <View className="flex items-center h-12 border-b border-gray-200 dark:border-gray-700">
          <Button
            onClick={() => setShowGenerationList(!showGenerationList)}
            type="basic"
            variant="ghost"
            className="w-12 h-12 flex items-center justify-center"
          >
            <Icon
              name={showGenerationList ? "chevron-right" : "chevron-left"}
              size={24}
              className="text-gray-500"
            />
          </Button>
          {showGenerationList && <Text className="text-sm font-medium text-gray-700 dark:text-gray-200">Generation History</Text>}
        </View>
        {showGenerationList && (
          <View className="h-[calc(100%-3rem)] overflow-y-auto">
            <GenerationList
              generations={generations.map((g) => ({
                ...g,
                createdAt: typeof g.createdAt === "string" ? g.createdAt : new Date(g.createdAt).toISOString(),
                voiceName: combinedVoices.find((v) => v.id === g.voiceId)?.name || "Unknown Voice",
                model: g.quality === "high" ? "Studio" : g.quality === "voice" ? "Voice Changer" : "Flash",
                notes: g.notes,
              }))}
              onCreateNew={() => {}}
              onPlayGeneration={onClickPlayGenerationHandler}
              onDownload={onClickDownloadHandler}
              onGenerateWithConfig={(generation) => {
                setFormData({
                  ...formData,
                  voiceId: generation.voiceId,
                  text: generation.text,
                  notes: generation.notes || undefined,
                  quality: generation.model === "Studio" ? "high" : generation.model === "Voice Changer" ? "voice" : "low",
                });
              }}
              isPlayingGenerationId={isPlayingGenerationId || undefined}
              className="h-full"
              maxDisplayCount={50}
            />
          </View>
        )}
      </View>

      <TutorialOverlay
        isVisible={showTutorial}
        onClose={() => {
          navigation.navigate("/");
          setCurrentTutorialStep(0);
        }}
        currentStep={currentTutorialStep}
        onNextStep={() => setCurrentTutorialStep((prev) => prev + 1)}
        onPreviousStep={() => setCurrentTutorialStep((prev) => prev - 1)}
        steps={tutorialSteps}
      />

      <AddVoiceDialog
        isOpen={showAddVoiceDialog}
        onClose={onCloseVoiceDialog}
      />

      <Dialog
        isOpen={showInsufficientBalanceDialog}
        onClose={() => setShowInsufficientBalanceDialog(false)}
        title="Insufficient Balance"
      >
        <View className="p-4 space-y-4">
          <Text className="text-white">You don't have enough balance to perform this operation. Please add funds to your account to continue.</Text>
          <View className="flex justify-end gap-4">
            <Button
              onClick={() => setShowInsufficientBalanceDialog(false)}
              variant="ghost"
            >
              Cancel
            </Button>
            <Button
              onClick={onClickGoToProfile}
              variant="default"
            >
              Add Funds
            </Button>
          </View>
        </View>
      </Dialog>
    </View>
  );
};
