import React from "react";
import { clsx } from "clsx";
import { ApiKey } from "../../redux/reducers/userSlice";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { API_URL } from "../../config";

type ApiKeyListProps = {
  apiKeys: ApiKey[];
  onCreateKey: () => void;
  onDeleteKey: (keyId: string) => void;
  className?: string;
  isLoading?: boolean;
};

export const ApiKeyList = ({ apiKeys, onCreateKey, onDeleteKey, className, isLoading }: ApiKeyListProps) => {
  return (
    <View className={`bg-white dark:bg-gray-800 rounded-lg p-6 border border-gray-200 dark:border-gray-700 shadow-sm ${className}`}>
      <View className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
        <View className="space-y-2 mb-4 sm:mb-0">
          <Text
            variant="brand"
            className="text-xl font-semibold block"
          >
            API Keys
          </Text>
        </View>
        <Button
          onClick={onCreateKey}
          variant="default"
          className="w-full sm:w-auto"
        >
          Create New Key
        </Button>
      </View>

      {isLoading ? (
        <Text
          variant="muted"
          className="text-center py-8"
        >
          Loading...
        </Text>
      ) : !apiKeys.length ? (
        <Text
          variant="muted"
          className="text-center py-8"
        >
          No API keys yet. Create one to get started.
        </Text>
      ) : (
        <View className="grid grid-cols-1 gap-4">
          {apiKeys.map((key) => (
            <View
              key={key.id}
              className={clsx(
                "p-4 rounded-lg",
                "bg-gray-50 dark:bg-gray-700/50",
                "border border-gray-200 dark:border-gray-600",
                "flex justify-between items-center",
              )}
            >
              <View className="flex flex-col">
                <Text
                  variant="body"
                  className="font-semibold"
                >
                  {key.description || "Unnamed Key"}
                </Text>
                <Text
                  variant="muted"
                  className="text-sm"
                >
                  {key.lastUsedAt && `Last used ${new Date(key.lastUsedAt).toLocaleDateString()} • `}
                  Created {new Date(key.createdAt).toLocaleDateString()}
                </Text>
              </View>
              <Button
                onClick={() => onDeleteKey(key.id)}
                type="basic"
                variant="ghost"
                className="!w-8 !h-8 !rounded-full"
              >
                <Icon
                  name="delete-outline"
                  size={18}
                />
              </Button>
            </View>
          ))}
        </View>
      )}

      <View className="flex flex-col mt-6">
        <Text variant="muted">
          Documentation for the API can be found{" "}
          <a
            href={`${API_URL}/docs`}
            target="_blank"
            className="text-brand hover:text-brand-light transition-colors"
          >
            here
          </a>
          .
        </Text>
      </View>
    </View>
  );
};
