import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";

import { View } from "../components/base/View";
import { SearchField } from "../components/base/SearchField";
import { ScrollView } from "../components/base/ScrollView";
import { VoiceCard } from "../components/VoiceCard";
import { voiceActions } from "../redux/reducers/voiceSlice";
import { publicVoiceActions } from "../redux/reducers/publicVoiceSlice";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { Text } from "../components/base/Text";
import { Button } from "../components/base/Button";
import { Dialog } from "../components/base/Dialog";
import { ExtendedVoice, ExtendedPublicVoice } from "../types/voice";
import { CreateVoiceModalContent } from "../components/CreateVoiceModalContent";

export const VoicesPage = () => {
  const navigation = useNavigation();
  const [searchQuery, setSearchQuery] = useState("");
  const [isPlayingVoiceId, setIsPlayingVoiceId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<"base" | "community" | "my">("base");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [baseVoiceId, setBaseVoiceId] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  // Filter states
  const [genderFilter, setGenderFilter] = useState<string>("all");
  const [accentFilter, setAccentFilter] = useState<string>("all");
  const [pitchFilter, setPitchFilter] = useState<string>("all");

  const audioRef = useRef<HTMLAudioElement>();
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const voices = useAppSelector((state) => Object.values(state.voice.entities) || []) as ExtendedVoice[];
  const publicVoices = useAppSelector((state) => Object.values(state.publicVoice.entities) || []) as ExtendedPublicVoice[];

  useEffect(() => {
    dispatch(voiceActions.get({}));
    dispatch(publicVoiceActions.get({}));
  }, [dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && activeTab === "community" && hasMore && !isLoadingMore) {
          loadMoreCommunityVoices();
        }
      },
      { threshold: 0.5 },
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [activeTab, hasMore, isLoadingMore]);

  const loadMoreCommunityVoices = async () => {
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    try {
      const result = await dispatch(
        publicVoiceActions.get({
          page: String(page + 1),
          limit: "20",
        }),
      ).unwrap();

      setPage((prev) => prev + 1);
      setHasMore(result.length === 20);
    } catch (error) {
      console.error("Failed to load more voices:", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const onChangeSearchQuery = (value: string) => {
    setSearchQuery(value);
  };

  const onClickPlayHandler = (voiceId: string) => () => {
    const voice = voices.find((voice) => voice.id === voiceId) || publicVoices.find((voice) => voice.id === voiceId);

    if (voice) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = undefined;
        setIsPlayingVoiceId(null);
      }

      if (isPlayingVoiceId !== voiceId) {
        setIsPlayingVoiceId(voiceId);

        audioRef.current = document.createElement("audio");

        audioRef.current.src = voice.previewUrl;
        audioRef.current.onended = () => {
          setIsPlayingVoiceId(null);
        };

        audioRef.current.play();
      }
    }
  };

  const onClickCreateHandler = (voiceId: string) => () => {
    setShowCreateModal(true);
    setBaseVoiceId(voiceId);
  };

  // Separate voices by type
  const baseVoices = voices.filter((v) => v.type === "base");
  const communityVoices = publicVoices.filter((v) => !v.isOwner);
  const myVoices = voices; // All voices in the main voices array are user's voices

  // Get visible voices based on active tab
  let visibleVoices = [];
  if (activeTab === "base") {
    visibleVoices = baseVoices;
  } else if (activeTab === "community") {
    visibleVoices = communityVoices;
  } else {
    visibleVoices = myVoices;
  }

  const filteredVoices = visibleVoices
    .filter((voice) => {
      const matchesSearch =
        voice.name.toLowerCase().includes(searchQuery.toLowerCase()) || voice.description?.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesGender = genderFilter === "all" || voice.gender === genderFilter;
      const matchesAccent = accentFilter === "all" || voice.accent === accentFilter;
      const matchesPitch = pitchFilter === "all" || voice.pitch === pitchFilter;

      return matchesSearch && matchesGender && matchesAccent && matchesPitch;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <View className="min-h-screen bg-white dark:bg-gray-900">
      <View className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Tab Navigation */}
        <View className="flex justify-center mb-8">
          <View className="flex space-x-2 bg-gray-100 dark:bg-gray-800 p-1 rounded-lg">
            <Button
              onClick={() => setActiveTab("base")}
              variant={activeTab === "base" ? "default" : "ghost"}
              className="!px-6 !py-2"
            >
              Base Voices
            </Button>
            <Button
              onClick={() => setActiveTab("community")}
              variant={activeTab === "community" ? "default" : "ghost"}
              className="!px-6 !py-2"
            >
              Community Voices
            </Button>
            <Button
              onClick={() => setActiveTab("my")}
              variant={activeTab === "my" ? "default" : "ghost"}
              className="!px-6 !py-2"
            >
              My Voices
            </Button>
          </View>
        </View>

        {/* Main Content */}
        <View className="flex gap-8">
          {/* Sidebar Filters */}
          <View className="hidden lg:block w-64 flex-shrink-0">
            <View className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
              <Text className="font-semibold mb-4">Filters</Text>

              {/* Gender Filter */}
              <View className="mb-4">
                <Text className="text-sm mb-2">Gender</Text>
                <select
                  value={genderFilter}
                  onChange={(e) => setGenderFilter(e.target.value)}
                  className="w-full p-2 rounded-md border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
                >
                  <option value="all">All</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="neutral">Neutral</option>
                </select>
              </View>

              {/* Accent Filter */}
              <View className="mb-4">
                <Text className="text-sm mb-2">Accent</Text>
                <select
                  value={accentFilter}
                  onChange={(e) => setAccentFilter(e.target.value)}
                  className="w-full p-2 rounded-md border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
                >
                  <option value="all">All</option>
                  <option value="american">American</option>
                  <option value="british">British</option>
                  <option value="australian">Australian</option>
                </select>
              </View>

              {/* Pitch Filter */}
              <View className="mb-4">
                <Text className="text-sm mb-2">Pitch</Text>
                <select
                  value={pitchFilter}
                  onChange={(e) => setPitchFilter(e.target.value)}
                  className="w-full p-2 rounded-md border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
                >
                  <option value="all">All</option>
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </View>
            </View>
          </View>

          {/* Voice Grid Section */}
          <View className="flex-1">
            {/* Search and Create Section */}
            <View className="flex items-center justify-between gap-4 mb-8">
              <View className="flex-1">
                <SearchField
                  placeholder="Search voices by name or description..."
                  value={searchQuery}
                  onChangeSearchQuery={onChangeSearchQuery}
                  className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm"
                  options={[]}
                />
              </View>
              <Button
                onClick={() => setShowCreateModal(true)}
                variant="default"
                className="!px-6 whitespace-nowrap"
              >
                Create New Voice
              </Button>
            </View>

            <View className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-8">
              {filteredVoices.length === 0 ? (
                <View className="text-center py-12 col-span-full">
                  <Text
                    variant="muted"
                    className="text-lg"
                  >
                    No voices found matching your search
                  </Text>
                </View>
              ) : (
                <>
                  {filteredVoices.map((voice, index) => (
                    <motion.div
                      key={voice.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.05 }}
                      className="h-full"
                    >
                      <VoiceCard
                        voice={voice}
                        index={index}
                        onClickPlay={onClickPlayHandler(voice.id)}
                        onClickCreate={onClickCreateHandler(voice.id)}
                        isPlaying={isPlayingVoiceId === voice.id}
                      />
                    </motion.div>
                  ))}
                  {activeTab === "community" && hasMore && (
                    <View
                      ref={loadMoreRef}
                      className="col-span-full flex justify-center py-8"
                    >
                      {isLoadingMore && <Text variant="muted">Loading more voices...</Text>}
                    </View>
                  )}
                </>
              )}
            </View>
          </View>
        </View>
      </View>

      {/* Create Voice Modal */}
      <Dialog
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      >
        <CreateVoiceModalContent
          onClose={() => setShowCreateModal(false)}
          baseVoiceId={baseVoiceId}
        />
      </Dialog>
    </View>
  );
};
