import React, { useState } from "react";
import GoogleButton from "react-google-button";

import { Button } from "../base/Button";
import { Form } from "../base/Form";
import { InputField } from "../base/InputField";
import { View } from "../base/View";
import { Text } from "../base/Text";
import { GOOGLE_OAUTH_URL } from "../../config";

type SignInFormData = {
  email: string;
  password: string;
};

type SignInFormProps = {
  onSubmit: (data: SignInFormData) => void;
  isLoading?: boolean;
  error?: string;
};

export const SignInForm = ({ onSubmit, isLoading, error }: SignInFormProps) => {
  const [formData, setFormData] = useState<SignInFormData>({ email: "", password: "" });

  return (
    <View className="space-y-6">
      <Form
        data={formData}
        onChange={setFormData}
      >
        <View className="space-y-4">
          <InputField
            label="Email"
            name="email"
            placeholder="Enter your email"
            error={error}
          />
          <InputField
            label="Password"
            name="password"
            type="password"
            placeholder="Enter your password"
            error={error}
          />
        </View>
      </Form>

      <View className="space-y-6">
        <Button
          isLoading={isLoading}
          onClick={() => onSubmit(formData)}
          className="w-full h-[50px] text-base"
        >
          Sign In
        </Button>

        <View className="relative">
          <View className="absolute inset-0 flex items-center">
            <View className="w-full border-t border-gray-200 dark:border-gray-800" />
          </View>
          <View className="relative flex justify-center text-sm">
            <Text
              variant="muted"
              className="px-2 bg-white dark:bg-gray-800"
            >
              Or continue with
            </Text>
          </View>
        </View>

        <View className="transition-transform duration-200 hover:scale-[1.02] active:scale-[0.98]">
          <GoogleButton
            type="light"
            style={{
              width: "100%",
              borderRadius: "0.5rem",
              fontFamily: "inherit",
            }}
            onClick={() => (window.location.href = GOOGLE_OAUTH_URL)}
          />
        </View>
      </View>
    </View>
  );
};
