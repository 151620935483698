import { useState } from "react";

import { Button } from "./base/Button";
import { CheckboxField } from "./base/CheckboxField";
import { Dialog } from "./base/Dialog";
import { fileActions } from "../redux/reducers/fileSlice";
import { FileField } from "./base/FileField";
import { InputField } from "./base/InputField";
import { Text } from "./base/Text";
import { useAppDispatch } from "../redux";
import { View } from "./base/View";
import { voiceActions } from "../redux/reducers/voiceSlice";

type AddVoiceDialogProps = {
  isOpen: boolean;
  onClose: (newVoiceName?: string) => void;
};

export const AddVoiceDialog = ({ isOpen, onClose }: AddVoiceDialogProps) => {
  const dispatch = useAppDispatch();
  const [newVoiceName, setNewVoiceName] = useState("");
  const [voiceSampleFile, setVoiceSampleFile] = useState<FileList>();
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClickAddVoice = async () => {
    if (!newVoiceName || !voiceSampleFile || !isVerified) return;

    setIsLoading(true);

    try {
      const fileUrl = await dispatch(
        fileActions.uploadFile({
          type: "audio",
          file: voiceSampleFile[0],
        }),
      ).unwrap();

      const newVoice = await dispatch(
        voiceActions.create({
          name: newVoiceName,
          sampleUrl: fileUrl,
          verified: isVerified,
        }),
      ).unwrap();

      setNewVoiceName("");
      setVoiceSampleFile(undefined);
      setIsVerified(false);
      setIsLoading(false);
      onClose(newVoice.id);
    } catch (error) {
      setIsLoading(false);
      console.error("Failed to create voice:", error);
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Add New Voice"
    >
      <View className="p-6 space-y-6">
        <View className="space-y-2">
          <Text variant="body">Voice Name</Text>
          <InputField
            value={newVoiceName}
            onChange={setNewVoiceName}
            placeholder="Enter voice name..."
            className="w-full"
          />
        </View>

        <View className="space-y-2">
          <Text variant="body">Voice Sample</Text>
          <FileField
            value={voiceSampleFile}
            onChange={setVoiceSampleFile}
            size="md"
            fileTypes={["wav", "mp3", "m4a", "flac", "ogg"]}
            className="w-full"
          />
          <Text
            variant="muted"
            className="text-xs"
          >
            Supported formats: WAV, MP3, M4A, FLAC, OGG
          </Text>
        </View>

        <View className="flex flex-row items-start gap-3 bg-gray-50 dark:bg-gray-800 p-4 rounded-lg">
          <CheckboxField
            value={isVerified}
            onChange={setIsVerified}
            className="mt-1"
          />
          <Text
            variant="muted"
            className="text-xs"
          >
            I verify that I have the necessary rights and permissions to use this voice for generation purposes, and I accept responsibility for any misuse. By
            checking this box, I acknowledge that I have obtained proper consent from the voice owner or have the legal right to use this voice.
          </Text>
        </View>

        <Button
          onClick={onClickAddVoice}
          variant="brand"
          className="w-full"
          isDisabled={!newVoiceName || !voiceSampleFile || !isVerified}
          isLoading={isLoading}
        >
          Add Voice
        </Button>
      </View>
    </Dialog>
  );
};
