import { useEffect } from "react";

import { View } from "../components/base/View";
import { Text } from "../components/base/Text";
import { Spinner } from "../components/base/Spinner";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch } from "../redux";

type SignOutPageProps = {
  className?: string;
};

export const SignOutPage = ({ className }: SignOutPageProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(sessionActions.signOut());
  }, []);

  return (
    <View className={`min-h-screen bg-white dark:bg-gray-900 ${className}`}>
      <View className="w-full max-w-5xl mx-auto p-8 flex flex-col items-center justify-center min-h-screen space-y-6">
        <Spinner className="w-12 h-12 text-brand dark:text-brand-light" />
        <Text
          variant="muted"
          className="text-lg text-center"
        >
          Signing out...
        </Text>
      </View>
    </View>
  );
};
