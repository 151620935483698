import { createRestSlice } from "./restSlice";

export type PublicVoice = {
  id: string;
  name: string;
  description: string;
  tags: string;
  sampleUrl: string;
  previewUrl: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const publicVoiceSlice = createRestSlice<PublicVoice>("publicVoice", "/v1/publicVoices");

export const publicVoiceReducer = publicVoiceSlice.reducer;
export const publicVoiceActions = publicVoiceSlice.actions;
