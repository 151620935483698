import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./redux";
import { ThemeProvider } from "./contexts/ThemeContext";

import { AdminLayout } from "./layouts/AdminLayout";
import { AdminPublicVoicesPage } from "./pages/admin/AdminPublicVoicesPage";
import { AdminTrainingJobsPage } from "./pages/admin/AdminTrainingJobsPage";
import { AdminUsersPage } from "./pages/admin/AdminUsersPage";
import { API_URL } from "./config";
import { AppLayout } from "./layouts/AppLayout";
import { AuthenticatedLayout } from "./layouts/AuthenticatedLayout";
import { CreatePage } from "./pages/CreatePage";
import { ForgotPasswordPage } from "./pages/ForgotPassword";
import { NotFoundPage } from "./pages/NotFoundPage";
import { ProfilePage } from "./pages/ProfilePage";
import { RedirectPage } from "./pages/RedirectPage";
import { SignInPage } from "./pages/SignInPage";
import { SignOutPage } from "./pages/SignOutPage";
import { SignUpPage } from "./pages/SignUpPage";
import { VoicesPage } from "./pages/VoicesPage";
import { initializeSocket } from "./utils/socket";

initializeSocket();

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<AppLayout />}
            >
              <Route
                path="/signin"
                element={<SignInPage />}
              />
              <Route
                path="/signup"
                element={<SignUpPage />}
              />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordPage />}
              />
              <Route
                path="/"
                element={<AuthenticatedLayout />}
              >
                <Route
                  path="/create"
                  element={<CreatePage />}
                />
                <Route
                  path="/profile"
                  element={<ProfilePage />}
                />
                <Route
                  path="/voices"
                  element={<VoicesPage />}
                />
                <Route
                  path="/signout"
                  element={<SignOutPage />}
                />
                <Route
                  path="/"
                  element={<RedirectPage path="/create" />}
                />
              </Route>
              <Route
                path="/admin"
                element={<AdminLayout />}
              >
                <Route
                  path="/admin"
                  element={<RedirectPage path="/admin/users" />}
                />
                <Route
                  path="/admin/users"
                  element={<AdminUsersPage />}
                />
                <Route
                  path="/admin/publicVoices"
                  element={<AdminPublicVoicesPage />}
                />
                <Route
                  path="/admin/trainingJobs"
                  element={<AdminTrainingJobsPage />}
                />
              </Route>
              <Route
                path="/docs"
                element={<RedirectPage path={`${API_URL}/docs`} />}
              />
              <Route
                path="*"
                element={<NotFoundPage />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
