import clsx from "clsx";
import { Button } from "./base/Button";
import { Text } from "./base/Text";
import { View } from "./base/View";
import { ModelCard } from "./base/ModelCard";
import { SparklesIcon, SwitchHorizontalIcon, LightningBoltIcon } from "@heroicons/react/outline";
import { Switch } from "./base/Switch";

interface ModelSelectorProps {
  onChange: (mode: string) => void;
  activeMode?: string;
  className?: string;
  directableRef: React.RefObject<HTMLDivElement>;
  basicRef: React.RefObject<HTMLDivElement>;
  voiceConversionRef: React.RefObject<HTMLDivElement>;
  isMobile?: boolean;
}

// Add these interfaces
interface BaseOption {
  readonly id: string;
  readonly title: string;
  readonly details: readonly string[];
  readonly icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
  readonly subtitle?: string;
}

interface CategoryOption extends BaseOption {
  readonly isCategory: true;
  readonly parentCategory?: never;
}

interface ProductOption extends BaseOption {
  readonly isCategory?: never;
  readonly parentCategory: string;
}

type Option = CategoryOption | ProductOption;

// Update the type of OPTIONS
const OPTIONS: readonly Option[] = [
  // Text->Speech Category
  {
    id: "category-1",
    title: "Speech Generation",
    isCategory: true,
    details: ["Ultra-realistic, fully directable voices", "Perfect for audiobooks and content", "Advanced control over tone and emotion"],
  },
  {
    id: "high",
    icon: SparklesIcon,
    title: "Prim Studio",
    subtitle: "$0.15/1K characters",
    parentCategory: "category-1",
    details: ["One-of-its-kind, directable generation", "Realistic voices that bring stories to life", "Limitless creativity"],
  },
  {
    id: "low",
    icon: LightningBoltIcon,
    title: "Prim Flash",
    subtitle: "$0.035/1K characters",
    parentCategory: "category-1",
    details: ["Blazing fast voice generation", "Cost-effective for volume production", "Simple and easy to use"],
  },

  // Speech->Speech Category
  {
    id: "category-3",
    title: "Speech Conversion",
    isCategory: true,
    details: ["Transform voices in real-time", "Perfect for live streaming", "Advanced voice modification options"],
  },
  {
    id: "voice",
    icon: SwitchHorizontalIcon,
    title: "Voice Changer",
    subtitle: "$0.025/minute",
    parentCategory: "category-3",
    details: ["Transform existing speech files", "Customize voices for specific needs", "Perfect for when you need total control"],
  },
] as const;

// Add gradient mapping for models
const MODEL_GRADIENTS: Record<string, string> = {
  high: "from-pink-500 to-purple-800",
  low: "from-orange-600 to-yellow-400",
  voice: "from-cyan-500 to-teal-500",
};

export const ModelSelector = ({ onChange, activeMode, className, directableRef, basicRef, voiceConversionRef, isMobile = false }: ModelSelectorProps) => {
  const getCategoryRef = (categoryId: string) => {
    switch (categoryId) {
      case "category-1":
        return directableRef;
      case "category-2":
        return basicRef;
      case "category-3":
        return voiceConversionRef;
      default:
        return null;
    }
  };

  // Group categories and their models together
  const groupedOptions = OPTIONS.reduce<{ [key: string]: Option[] }>((acc, option) => {
    if (option.isCategory) {
      acc[option.id] = [option];
    } else if (option.parentCategory) {
      acc[option.parentCategory].push(option);
    }
    return acc;
  }, {});

  if (isMobile) {
    const mobileOptions = [
      { label: "Prim Studio", value: "high", gradient: MODEL_GRADIENTS.high, Icon: SparklesIcon },
      { label: "Prim Flash", value: "low", gradient: MODEL_GRADIENTS.low, Icon: LightningBoltIcon },
      { label: "Voice Changer", value: "voice", gradient: MODEL_GRADIENTS.voice, Icon: SwitchHorizontalIcon },
    ];

    return (
      <View className="flex flex-col w-full max-w-lg mx-auto mb-4 md:hidden">
        <View className="flex gap-2 p-1 bg-white dark:bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-200/50 dark:border-gray-700/50">
          {mobileOptions.map((option) => (
            <Button
              key={option.value}
              onClick={() => onChange(option.value)}
              className={clsx(
                "flex-1 !px-1 !py-2 rounded-md text-xs font-medium transition-all duration-200",
                "flex items-center justify-center gap-2",
                activeMode === option.value
                  ? `bg-gradient-to-r ${option.gradient} text-white shadow-lg`
                  : "text-gray-500 dark:text-gray-400 hover:text-white bg-gradient-to-r from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800 hover:from-gray-200 hover:to-gray-300 dark:hover:from-gray-600 dark:hover:to-gray-700",
              )}
            >
              <option.Icon className="w-4 h-4" />
              {option.label}
            </Button>
          ))}
        </View>
      </View>
    );
  }

  return (
    <View className={clsx("w-72 h-full bg-white dark:bg-gray-800", "border-r border-gray-200 dark:border-gray-700", "hidden md:flex flex-col", className)}>
      {Object.entries(groupedOptions).map(([categoryId, options]) => (
        <View
          key={categoryId}
          ref={getCategoryRef(categoryId)}
          className="relative"
        >
          {options.map((option) =>
            option.isCategory ? (
              <Button
                key={option.id}
                type="basic"
                variant="ghost"
                className="flex items-center justify-start w-full !rounded-none transition-all duration-200 text-left p-4 bg-gray-50 dark:bg-gray-700/50 uppercase tracking-wider font-bold text-sm cursor-default pointer-events-none"
              >
                <Text variant="muted">{option.title}</Text>
              </Button>
            ) : (
              <ModelCard
                key={option.id}
                icon={option.icon}
                title={option.title}
                cost={option.subtitle}
                details={option.details}
                gradient={MODEL_GRADIENTS[option.id]}
                isActive={activeMode === option.id}
                onClick={() => onChange(option.id)}
              />
            ),
          )}
        </View>
      ))}
    </View>
  );
};
