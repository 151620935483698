import { useState, useEffect } from "react";
import { View } from "./View";
import { Text } from "./Text";
import { clsx } from "clsx";
import { createPortal } from "react-dom";

interface TooltipProps {
  content: string;
  children: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
}

interface TooltipPosition {
  top: number;
  left: number;
  transform: string;
}

export const Tooltip = ({ content, children, placement = "top" }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({ top: 0, left: 0, transform: "translate(-50%, -100%)" });
  const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (triggerElement && isVisible) {
      const rect = triggerElement.getBoundingClientRect();
      const positions = {
        top: {
          top: rect.top - 10,
          left: rect.left + rect.width / 2,
          transform: "translate(-50%, -100%)",
        },
        bottom: {
          top: rect.bottom + 10,
          left: rect.left + rect.width / 2,
          transform: "translate(-50%, 0)",
        },
        left: {
          top: rect.top + rect.height / 2,
          left: rect.left - 10,
          transform: "translate(-100%, -50%)",
        },
        right: {
          top: rect.top + rect.height / 2,
          left: rect.right + 10,
          transform: "translate(0, -50%)",
        },
      };

      setTooltipPosition(positions[placement]);
    }
  }, [isVisible, placement, triggerElement]);

  return (
    <>
      <View
        ref={setTriggerElement}
        className="relative inline-block"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={() => setIsVisible(!isVisible)}
      >
        {children}
      </View>
      {isVisible &&
        createPortal(
          <View
            className={clsx(
              "fixed px-3 py-2 text-sm rounded-lg shadow-lg",
              "bg-white dark:bg-gray-800",
              "text-gray-900 dark:text-gray-100",
              "border border-gray-200 dark:border-gray-600",
              "backdrop-blur-sm",
              "whitespace-nowrap",
              "transition-all duration-200",
              "z-[9999]",
              isVisible ? "opacity-100 scale-100" : "opacity-0 scale-95 pointer-events-none",
            )}
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              transform: tooltipPosition.transform,
            }}
          >
            <Text className="text-sm font-medium">{content}</Text>
          </View>,
          document.body,
        )}
    </>
  );
};
