import { makeRequest } from "../../utils/request";
import { RestList } from "../../components/shared/RestList";
import { User } from "../../redux/reducers/userSlice";
import { View } from "../../components/base/View";

type AdminPublicVoicesPageProps = {
  className?: string;
};

export const AdminPublicVoicesPage = ({ className }: AdminPublicVoicesPageProps) => {
  const search = async (query: string) => {
    try {
      const { data } = await makeRequest<{ data: User[] }>(`/v1/publicVoices/search?q=${query}`);
      return data.map((publicVoice) => publicVoice.id);
    } catch (error) {
      console.error("Error searching publicVoices:", error);
    }
    return [];
  };

  return (
    <View className="h-full bg-gray-50 dark:bg-gray-900">
      <RestList
        className={className}
        entityName="publicVoice"
        search={search}
        fields={[
          {
            name: "id",
            label: "ID",
            readOnly: true,
          },
          {
            name: "name",
            label: "Name",
          },
          {
            name: "description",
            label: "Description",
            type: "textarea",
          },
          {
            name: "tags",
            label: "Tags",
          },
          {
            name: "sampleUrl",
            label: "Sample URL",
            type: "audio",
            visible: false,
          },
          {
            name: "previewUrl",
            label: "Preview URL",
            type: "audio",
            visible: false,
          },
        ]}
      />
    </View>
  );
};
