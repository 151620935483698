import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { View } from "../components/base/View";
import { Text } from "../components/base/Text";
import { Button } from "../components/base/Button";

type NotFoundPageProps = {
  className?: string;
};

export const NotFoundPage = ({ className }: NotFoundPageProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (pathname.indexOf("admin") > -1) {
        navigate("/admin");
      } else {
        navigate("/");
      }
    }, 3000); // Redirect after 3 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <View className={`min-h-screen bg-white dark:bg-gray-900 ${className}`}>
      <View className="w-full max-w-5xl mx-auto p-8 flex flex-col items-center justify-center min-h-screen space-y-6">
        <Text
          variant="brand"
          className="text-6xl font-bold"
        >
          404
        </Text>
        <Text
          variant="body"
          className="text-2xl font-semibold text-center"
        >
          Page Not Found
        </Text>
        <Text
          variant="muted"
          className="text-center max-w-md"
        >
          The page you're looking for doesn't exist. You'll be redirected to the {pathname.indexOf("admin") > -1 ? "admin" : "home"} page shortly.
        </Text>
        <Button
          variant="default"
          onClick={() => navigate(pathname.indexOf("admin") > -1 ? "/admin" : "/")}
        >
          Go Back Now
        </Button>
      </View>
    </View>
  );
};
