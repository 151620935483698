import { motion } from "framer-motion";
import { Text } from "./Text";
import { View } from "./View";
import { Button } from "./Button";
import clsx from "clsx";
import React from "react";

interface ModelCardProps {
  readonly icon?: React.ComponentType<React.SVGProps<SVGSVGElement>> | string;
  readonly title: string;
  readonly details: readonly string[];
  readonly gradient: string;
  readonly isActive?: boolean;
  readonly onClick?: () => void;
  readonly cost?: string;
}

export const ModelCard = ({ icon, title, details, gradient, isActive, onClick, cost }: ModelCardProps) => {
  const renderIcon = () => {
    if (!icon) return null;

    if (typeof icon === "string") {
      return (
        <Text
          className={clsx(
            "text-3xl inline-block",
            isActive ? "text-white" : "text-gray-700 dark:text-gray-300",
            "[text-shadow:_-1px_-1px_0_#fff,_1px_-1px_0_#fff,_-1px_1px_0_#fff,_1px_1px_0_#fff]",
            "dark:[text-shadow:_-1px_-1px_0_#fff,_1px_-1px_0_#fff,_-1px_1px_0_#fff,_1px_1px_0_#fff]",
          )}
        >
          {icon}
        </Text>
      );
    }

    const IconComponent = icon;
    return <IconComponent className={clsx("w-6 h-6", isActive ? "text-white" : "text-gray-700 dark:text-gray-300")} />;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div
        className={clsx("relative group hover:scale-[1.02] transition-all duration-300 cursor-pointer overflow-hidden")}
        onClick={onClick}
      >
        {/* Card background with gradient */}
        <div
          className={clsx(
            "absolute inset-0 bg-gradient-to-br transition-all duration-300",
            isActive ? gradient : "bg-transparent",
            isActive ? "opacity-80 group-hover:opacity-100" : "opacity-0 group-hover:opacity-10",
          )}
        />

        {/* Content */}
        <div className="relative p-4">
          {/* Title Section */}
          <div className="flex items-start gap-3 mb-2">
            {renderIcon()}
            <div>
              <Text className={clsx("text-lg font-semibold leading-tight", isActive ? "text-white" : "text-gray-900 dark:text-gray-100")}>{title}</Text>
            </div>
          </div>

          {/* Feature Section */}
          <div className="mb-5">
            <View className="space-y-1.5">
              {details.map((detail, index) => (
                <Text
                  key={index}
                  className={clsx("text-xs flex items-start", isActive ? "text-white" : "text-gray-600 dark:text-gray-400")}
                >
                  <Text className={clsx("mr-2", isActive ? "text-white" : "text-gray-600 dark:text-gray-400")}>•</Text>
                  {detail}
                </Text>
              ))}
            </View>
          </div>

          {/* Cost display */}
          {cost && (
            <Text className={clsx("absolute bottom-2 right-3 text-xs font-normal", isActive ? "text-white/70" : "text-gray-500 dark:text-gray-400")}>
              {cost}
            </Text>
          )}

          {/* Decorative circles - only show when active */}
          {isActive && (
            <>
              <div className="absolute top-0 right-0 -translate-y-1/4 translate-x-1/4">
                <div className="w-24 h-24 rounded-full bg-white/5 blur-2xl" />
              </div>
              <div className="absolute bottom-0 left-0 translate-y-1/4 -translate-x-1/4">
                <div className="w-32 h-32 rounded-full bg-white/5 blur-2xl" />
              </div>
            </>
          )}

          {isActive && <View className="absolute top-2 right-2 w-2 h-2 rounded-full border border-white/90 bg-white/90" />}
        </div>
      </div>
    </motion.div>
  );
};
