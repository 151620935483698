import { View } from "./base/View";
import { Text } from "./base/Text";
import { Button } from "./base/Button";
import { Icon } from "./base/Icon";
import { useEffect, useState } from "react";
import { clsx } from "clsx";

interface TutorialStep {
  targetRef: React.RefObject<HTMLElement>;
  message: string;
  title: string;
}

interface TutorialOverlayProps {
  isVisible: boolean;
  onClose: () => void;
  currentStep: number;
  onNextStep: () => void;
  onPreviousStep: () => void;
  steps: TutorialStep[];
}

export const TutorialOverlay = ({ isVisible, onClose, currentStep, onNextStep, onPreviousStep, steps }: TutorialOverlayProps) => {
  const [highlightPosition, setHighlightPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [messagePosition, setMessagePosition] = useState<"bottom" | "top" | "right" | "below" | "above">("bottom");
  const [isMobile, setIsMobile] = useState(false);
  const [isMessageTransitioning, setIsMessageTransitioning] = useState(false);

  // Add window resize handler
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // matches our md: breakpoint
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    if (isVisible && steps[currentStep]?.targetRef.current) {
      const element = steps[currentStep].targetRef.current;
      if (element) {
        // First scroll element into view at top
        element.scrollIntoView({ behavior: "smooth", block: "start" });

        // Wait for scroll to complete before setting positions
        setTimeout(() => {
          const rect = element.getBoundingClientRect();

          // Set message position based on step and device
          if (currentStep < 3) {
            if (isMobile) {
              setMessagePosition("below"); // Show below for mobile Switch component
            } else {
              setMessagePosition("right"); // Show right for desktop sidebar
            }
          } else if (currentStep === 3 || currentStep === 4) {
            setMessagePosition("below");
          } else {
            setMessagePosition("above");
          }

          // Set highlight position
          if (isMobile && currentStep < 3) {
            const switchOptions = document.querySelectorAll(".md\\:hidden button");
            let targetOption;

            switch (currentStep) {
              case 0:
                targetOption = switchOptions[0]; // Prim Studio
                break;
              case 1:
                targetOption = switchOptions[1]; // Prim Flash
                break;
              case 2:
                targetOption = switchOptions[2]; // Voice Changer
                break;
            }

            if (targetOption) {
              const rect = targetOption.getBoundingClientRect();
              setHighlightPosition({
                top: rect.top,
                left: rect.left,
                width: rect.width,
                height: rect.height,
              });
            }
          } else {
            setHighlightPosition({
              top: rect.top,
              left: rect.left,
              width: rect.width,
              height: rect.height,
            });
          }
        }, 300); // Wait for scroll animation
      }
    }
  }, [isVisible, currentStep, steps, isMobile, messagePosition]);

  useEffect(() => {
    // Trigger transition animation when message position changes
    setIsMessageTransitioning(true);
    const timer = setTimeout(() => setIsMessageTransitioning(false), 50);
    return () => clearTimeout(timer);
  }, [messagePosition]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isVisible) return null;

  const padding = 8;

  return (
    <View
      className="fixed inset-0 z-[1000]"
      onClick={handleOverlayClick}
    >
      {/* Overlay with hole effect */}
      <View
        className="absolute inset-0 bg-black/30 dark:bg-black/50 transition-all duration-500 ease-in-out"
        style={{
          opacity: 0.5,
          clipPath: `polygon(
            0% 0%,
            100% 0%,
            100% 100%,
            0% 100%,
            0% ${highlightPosition.top - padding}px,
            ${highlightPosition.left - padding}px ${highlightPosition.top - padding}px,
            ${highlightPosition.left - padding}px ${highlightPosition.top + highlightPosition.height + padding}px,
            ${highlightPosition.left + highlightPosition.width + padding}px ${highlightPosition.top + highlightPosition.height + padding}px,
            ${highlightPosition.left + highlightPosition.width + padding}px ${highlightPosition.top - padding}px,
            0% ${highlightPosition.top - padding}px
          )`,
        }}
      />

      {/* Highlight border */}
      <View
        className="absolute border-2 border-gray-200 dark:border-white rounded-lg transition-all duration-500 ease-in-out"
        style={{
          top: highlightPosition.top - padding,
          left: highlightPosition.left - padding,
          width: highlightPosition.width + padding * 2,
          height: highlightPosition.height + padding * 2,
        }}
      />

      {/* Add a wrapper div for mobile padding */}
      <View className={clsx("absolute w-full", isMobile && "px-6")}>
        <View
          className={clsx(
            "absolute bg-white dark:bg-gray-800 rounded-lg p-6",
            "border border-gray-200 dark:border-gray-700",
            "backdrop-blur-lg",
            "transform transition-all duration-500 ease-in-out",
            !isMobile && currentStep < 3 ? "max-w-xl" : "max-w-md",
          )}
          style={{
            ...(() => {
              if (isMobile) {
                const mobileWidth = window.innerWidth - 48;
                return {
                  width: mobileWidth,
                  left: "50%",
                  top: messagePosition === "above" ? highlightPosition.top - 16 : highlightPosition.top + highlightPosition.height + 16,
                  transform: `translate(-50%, ${messagePosition === "above" ? "-100%" : "0"}) scale(${isMessageTransitioning ? 0.95 : 1})`,
                };
              }

              // Desktop positioning
              switch (messagePosition) {
                case "right":
                  return {
                    left: highlightPosition.left + highlightPosition.width + 16,
                    ...(() => {
                      // Different alignments for first 3 steps
                      switch (currentStep) {
                        case 0:
                          // Top align
                          return {
                            top: highlightPosition.top,
                            transform: `translate(0, 0) scale(${isMessageTransitioning ? 0.95 : 1})`,
                          };
                        case 1:
                          // Center align
                          return {
                            top: highlightPosition.top + highlightPosition.height / 2,
                            transform: `translate(0, -50%) scale(${isMessageTransitioning ? 0.95 : 1})`,
                          };
                        case 2:
                          // Bottom align
                          return {
                            top: highlightPosition.top + highlightPosition.height,
                            transform: `translate(0, -100%) scale(${isMessageTransitioning ? 0.95 : 1})`,
                          };
                        default:
                          return {
                            top: highlightPosition.top,
                            transform: `translate(0, 0) scale(${isMessageTransitioning ? 0.95 : 1})`,
                          };
                      }
                    })(),
                    maxWidth: `min(36rem, calc(100vw - ${highlightPosition.left + highlightPosition.width + 48}px))`,
                  };
                case "below":
                  return {
                    left: highlightPosition.left + highlightPosition.width / 2,
                    top: highlightPosition.top + highlightPosition.height + 16,
                    transform: `translate(-50%, 0) scale(${isMessageTransitioning ? 0.95 : 1})`,
                  };
                case "above":
                  return {
                    left: highlightPosition.left + highlightPosition.width / 2,
                    top: highlightPosition.top - 16,
                    transform: `translate(-50%, -100%) scale(${isMessageTransitioning ? 0.95 : 1})`,
                  };
                default:
                  return {};
              }
            })(),
            opacity: isMessageTransitioning ? 0 : 1,
            transitionProperty: "transform, opacity, top, bottom, left",
          }}
        >
          {/* Title section with close button */}
          <View className="w-full pb-4 border-b border-gray-200 dark:border-gray-700 mb-6 flex items-center justify-between">
            <Text
              className={clsx(
                "text-gray-900 dark:text-white text-xl font-bold inline-block transition-opacity duration-500",
                isMessageTransitioning ? "opacity-0" : "opacity-100",
              )}
            >
              {steps[currentStep].title}
            </Text>
            <Button
              onClick={onClose}
              className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-white !w-7 h-7 !shadow-none"
              aria-label="Close dialog"
              type="basic"
            >
              <Icon
                name="close"
                size={24}
              />
            </Button>
          </View>

          {/* Message */}
          <View className="px-6">
            <Text
              className={clsx(
                "text-gray-900 dark:text-white text-lg text-left inline-block transition-opacity duration-500",
                isMessageTransitioning ? "opacity-0" : "opacity-100",
              )}
            >
              {steps[currentStep].message}
            </Text>
          </View>

          {/* Buttons */}
          <View
            className={clsx(
              "grid grid-cols-2 gap-2 border-t border-gray-200 dark:border-gray-700 pt-4 px-6 mt-6",
              "transition-opacity duration-500",
              isMessageTransitioning ? "opacity-0" : "opacity-100",
            )}
          >
            <Button
              onClick={currentStep === 0 ? undefined : onPreviousStep}
              variant="ghost"
              type="basic"
              className={clsx(
                "w-full text-gray-500 dark:text-gray-400 transition-colors duration-200 !shadow-none",
                currentStep === 0 ? "opacity-50 cursor-not-allowed" : "hover:text-gray-700 dark:hover:text-gray-300",
              )}
            >
              Back
            </Button>
            <Button
              onClick={currentStep === steps.length - 1 ? onClose : onNextStep}
              variant="default"
              className="w-full bg-brand hover:bg-brand-dark dark:bg-brand-light dark:hover:bg-brand text-white px-6 py-2 rounded-lg transition-all duration-200"
            >
              {currentStep === steps.length - 1 ? "Got it!" : "Next"}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};
