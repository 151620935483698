import { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import { ApiKey } from "../redux/reducers/userSlice";
import { Button } from "../components/base/Button";
import { Dialog } from "../components/base/Dialog";
import { InputField } from "../components/base/InputField";
import { Text } from "../components/base/Text";
import { useApiRequest } from "../hooks/useApiRequest";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/base/View";
import { voiceActions } from "../redux/reducers/voiceSlice";
import { generationActions } from "../redux/reducers/generationSlice";
import { AddVoiceDialog } from "../components/AddVoiceDialog";
import { STRIPE_PUBLISHABLE_KEY, API_URL } from "../config";
import { BalanceCard } from "../components/profile/BalanceCard";
import { ApiKeyList } from "../components/profile/ApiKeyList";
import { VoiceCard } from "../components/VoiceCard";
import { GenerationList } from "../components/profile/GenerationList";
import { Icon } from "../components/base/Icon";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const user = useAppSelector((state) => state.session.user);
  const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
  const [description, setDescription] = useState("");
  const [showApiKeyDialog, setShowApiKeyDialog] = useState(false);
  const [showPurchaseDialog, setShowPurchaseDialog] = useState(false);
  const [newApiKey, setNewApiKey] = useState("");
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [amount, setAmount] = useState(10);
  const [showAddVoiceDialog, setShowAddVoiceDialog] = useState(false);
  const createApiKeyRequest = useApiRequest<{ data: ApiKey }>();
  const getApiKeysRequest = useApiRequest<{ data: ApiKey[] }>();
  const revokeApiKeyRequest = useApiRequest();
  const createPaymentRequest = useApiRequest<{ sessionId: string }>();
  const [isPlayingVoiceId, setIsPlayingVoiceId] = useState("");
  const [isPlayingGenerationId, setIsPlayingGenerationId] = useState("");
  const currentPlayingVoice = useRef<HTMLAudioElement>();
  const currentPlayingGeneration = useRef<HTMLAudioElement>();
  const generations = Object.values(useAppSelector((state) => state.generation.entities));
  const voices = Object.values(useAppSelector((state) => state.voice.entities));

  // Calculate volume discount based on amount
  const calculateDiscount = (amount: number) => {
    if (amount >= 100) return 0.15; // 15% off for $100+
    if (amount >= 50) return 0.1; // 10% off for $50+
    return 0; // No discount
  };

  const onClickPurchaseAmount = async () => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error("Stripe failed to load");

      const response = await createPaymentRequest.fetch("/v1/payments/create-session", {
        method: "POST",
        body: { amount },
      });

      const result = await stripe.redirectToCheckout({
        sessionId: response.data?.sessionId || "",
      });

      if (result.error) {
        console.error(result.error);
      }
    } catch (err) {
      console.error("Payment error:", err);
    }
  };

  const onClickCreateApiKey = async () => {
    const response = await createApiKeyRequest.fetch("/v1/users/apiKeys", {
      method: "POST",
      body: { description },
    });

    if (response?.data) {
      setNewApiKey(response.data?.data?.token || "");
      setShowApiKeyDialog(true);
      setShowDescriptionDialog(false);
      setDescription("");
      getApiKeysRequest.fetch("/v1/users/apiKeys");
    }
  };

  const onClickDeleteApiKey = async (keyId: string) => {
    await revokeApiKeyRequest.fetch(`/v1/users/apiKeys/${keyId}`, {
      method: "DELETE",
    });
    getApiKeysRequest.fetch("/v1/users/apiKeys");
  };

  const onClickCopyApiKey = async () => {
    await navigator.clipboard.writeText(newApiKey);
    setShowCopySuccess(true);
    setTimeout(() => setShowCopySuccess(false), 1500);
  };

  const onClickPlayVoiceHandler = (voiceId: string, voiceSampleUrl: string) => {
    if (isPlayingVoiceId === voiceId) {
      currentPlayingVoice.current?.pause();
      setIsPlayingVoiceId("");
    } else {
      setIsPlayingVoiceId(voiceId);
      if (currentPlayingVoice.current) {
        currentPlayingVoice.current.pause();
      }
      currentPlayingVoice.current = new Audio(voiceSampleUrl);
      currentPlayingVoice.current.play();
      currentPlayingVoice.current.onended = () => setIsPlayingVoiceId("");
      currentPlayingVoice.current.onpause = () => setIsPlayingVoiceId("");
      currentPlayingVoice.current.onerror = () => setIsPlayingVoiceId("");
    }
  };

  const onClickPlayGenerationHandler = (generationId: string, audioUrl: string) => {
    if (isPlayingGenerationId === generationId) {
      currentPlayingGeneration.current?.pause();
      setIsPlayingGenerationId("");
    } else {
      setIsPlayingGenerationId(generationId);
      if (currentPlayingGeneration.current) {
        currentPlayingGeneration.current.pause();
      }
      currentPlayingGeneration.current = new Audio(audioUrl);
      currentPlayingGeneration.current.play();
      currentPlayingGeneration.current.onended = () => setIsPlayingGenerationId("");
      currentPlayingGeneration.current.onpause = () => setIsPlayingGenerationId("");
      currentPlayingGeneration.current.onerror = () => setIsPlayingGenerationId("");
    }
  };

  const onClickDownloadHandler = (audioUrl: string) => {
    if (audioUrl) {
      const link = document.createElement("a");
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `audio-${timestamp}.wav`;
      link.href = audioUrl;
      link.click();
      link.remove();
    }
  };

  useEffect(() => {
    getApiKeysRequest.fetch("/v1/users/apiKeys");
    dispatch(generationActions.get({}));
    dispatch(voiceActions.get({}));
  }, []);

  return (
    <View className="min-h-screen bg-white dark:bg-gray-900">
      <View className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <View className="flex flex-col gap-8">
          <View className="space-y-2">
            <Text
              variant="brand"
              className="text-4xl sm:text-5xl font-bold leading-tight block"
            >
              Your Account
            </Text>
          </View>

          {/* Account Section */}
          <View className="space-y-4">
            <View className="bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700">
              <View className="flex justify-between items-center p-6">
                <View className="flex flex-col gap-1">
                  <Text
                    variant="muted"
                    className="text-sm"
                  >
                    Email Address
                  </Text>
                  <Text className="text-lg">{user?.email}</Text>
                </View>
                <Button
                  variant="outline"
                  onClick={() => navigation.navigate("/signout")}
                  className="px-6 !text-red-600 !border-red-600 hover:!bg-red-50 dark:hover:!bg-red-950"
                >
                  Sign out
                </Button>
              </View>
            </View>
          </View>

          <BalanceCard
            balance={user?.balance || 0}
            onClickAddFunds={() => setShowPurchaseDialog(true)}
          />

          <ApiKeyList
            apiKeys={getApiKeysRequest.data?.data || []}
            onCreateKey={() => setShowDescriptionDialog(true)}
            onDeleteKey={onClickDeleteApiKey}
            isLoading={getApiKeysRequest.isLoading}
          />

          <View className="space-y-4">
            <View className="space-y-2">
              <Text
                variant="brand"
                className="text-2xl font-bold block"
              >
                Voices
              </Text>
            </View>

            <View className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <Button
                onClick={() => setShowAddVoiceDialog(true)}
                className="flex flex-col items-center justify-center p-6 border-2 border-dashed border-gray-300 dark:border-gray-700 rounded-lg hover:border-purple-500 dark:hover:border-purple-500 transition-colors"
              >
                <Text className="text-lg font-medium text-white">Add New Voice</Text>
              </Button>
              {voices.map((voice, index) => (
                <VoiceCard
                  key={voice.id}
                  voice={voice}
                  index={index}
                  onClickPlay={() => onClickPlayVoiceHandler(voice.id, voice.sampleUrl)}
                  onClickCreate={() => navigation.navigate("/create", { voiceId: voice.id })}
                  isPlaying={isPlayingVoiceId === voice.id}
                />
              ))}
            </View>
          </View>

          <GenerationList
            generations={generations.map((g) => ({
              ...g,
              createdAt: typeof g.createdAt === "string" ? g.createdAt : new Date(g.createdAt).toISOString(),
              voiceName: voices.find((v) => v.id === g.voiceId)?.name || "Unknown Voice",
              model: g.quality === "high" ? "Studio" : g.quality === "voice" ? "Voice Changer" : "Flash",
              notes: g.notes,
            }))}
            onCreateNew={() => navigation.navigate("/create")}
            onPlayGeneration={onClickPlayGenerationHandler}
            onDownload={onClickDownloadHandler}
            onGenerateWithConfig={(generation) =>
              navigation.navigate("/create", {
                voiceId: generation.voiceId,
                text: encodeURIComponent(generation.text),
                notes: generation.notes ? encodeURIComponent(generation.notes) : undefined,
              })
            }
            isPlayingGenerationId={isPlayingGenerationId}
            variant="grid"
            title="Generations"
          />
        </View>

        <AddVoiceDialog
          isOpen={showAddVoiceDialog}
          onClose={() => setShowAddVoiceDialog(false)}
        />

        <Dialog
          isOpen={showDescriptionDialog}
          onClose={() => setShowDescriptionDialog(false)}
          title="New API Key"
        >
          <View className="p-4 space-y-4">
            <Text variant="body">Enter a description for your new API key:</Text>
            <InputField
              value={description}
              onChange={(description) => setDescription(description)}
              placeholder="Enter key description..."
              className="w-full text-slate-700"
            />
            <Button
              onClick={onClickCreateApiKey}
              isDisabled={!description || createApiKeyRequest.isLoading}
              className="bg-brand hover:bg-brand-dark disabled:bg-brand/50 text-white px-4 py-2 w-full"
            >
              {createApiKeyRequest.isLoading ? "Generating..." : "Generate API Key"}
            </Button>
          </View>
        </Dialog>

        <Dialog
          isOpen={showApiKeyDialog}
          onClose={() => setShowApiKeyDialog(false)}
          title="Your New API Key"
        >
          <View className="flex flex-col p-4">
            <Text
              variant="body"
              className="mb-4"
            >
              Please save this API key - you won't be able to see it again!
            </Text>
            <Text
              variant="body"
              className="mb-4"
            >
              You can view documentation for the API{" "}
              <a
                href={`${API_URL}/docs`}
                target="_blank"
                className="text-brand hover:text-brand-light transition-colors"
              >
                here
              </a>
              .
            </Text>
            <View className="flex items-center space-x-2">
              <InputField
                value={newApiKey}
                readOnly
                className="flex-1"
              />
              <Button
                onClick={onClickCopyApiKey}
                variant="default"
                className="whitespace-nowrap"
              >
                {showCopySuccess ? "Copied!" : "Copy Key"}
              </Button>
            </View>
          </View>
        </Dialog>

        <Dialog
          isOpen={showPurchaseDialog}
          onClose={() => setShowPurchaseDialog(false)}
          title="Add Funds"
        >
          <View className="p-4 space-y-4">
            <Text className="text-white">Enter amount to add (USD):</Text>
            <InputField
              type="number"
              value={amount.toString()}
              onChange={(value) => setAmount(Number(value))}
              className="w-full"
            />
            {calculateDiscount(amount) > 0 && <Text className="text-white">Volume discount: {calculateDiscount(amount) * 100}% off</Text>}
            <Button
              onClick={onClickPurchaseAmount}
              isDisabled={amount <= 0 || createPaymentRequest.isLoading}
              className="bg-purple-600 hover:bg-purple-700 disabled:bg-purple-400 text-white px-4 py-2 w-full"
            >
              {createPaymentRequest.isLoading ? "Processing..." : "Proceed to Payment"}
            </Button>
          </View>
        </Dialog>
      </View>
    </View>
  );
};
