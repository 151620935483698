import { Outlet, useLocation } from "react-router-dom";
import clsx from "clsx";

import { View } from "../components/base/View";
import { useNavigation } from "../hooks/useNavigation";
import { AuthenticatedHeader } from "../components/layout/Header/AuthenticatedHeader";
import { Button } from "../components/base/Button";
import { Icon } from "../components/base/Icon";
import { RequireAuth } from "../components/auth/RequireAuth";

type AuthenticatedLayoutProps = {
  className?: string;
};

export const AuthenticatedLayout = ({ className }: AuthenticatedLayoutProps) => {
  const navigation = useNavigation();
  const { pathname } = useLocation();

  const onClickProfile = () => {
    navigation.navigate("/profile");
  };

  return (
    <RequireAuth className={className}>
      <View
        className={clsx(
          "relative flex flex-col w-full h-full overflow-scroll bg-gradient-to-b from-white dark:from-gray-900 to-gray-50 dark:to-gray-800",
          className,
        )}
      >
        <AuthenticatedHeader onClickProfile={onClickProfile} />

        <View className="flex-1 flex-grow">
          <Outlet />
        </View>

        {pathname === "/create" && (
          <Button
            onClick={() => navigation.navigate("/create?tutorial=true")}
            type="basic"
            className="!fixed bottom-4 left-4 !w-10 !h-10 bg-brand text-white dark:bg-brand-light rounded-full flex items-center justify-center hover:bg-brand-dark dark:hover:bg-brand transition-colors duration-200 z-50"
          >
            <Icon
              name="help-circle"
              size={24}
            />
          </Button>
        )}
      </View>
    </RequireAuth>
  );
};
