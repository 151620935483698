import { createRestSlice } from "./restSlice";

export type Generation = {
  id: string;
  userId: string;
  voiceId: string;
  text: string;
  notes?: string;
  sourceUrl: string;
  audioUrl: string;
  quality: string;
  cost: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

const generationSlice = createRestSlice<Generation>("generation", "/v1/generations");

export const generationReducer = generationSlice.reducer;
export const generationActions = generationSlice.actions;
