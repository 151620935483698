import { SvgIconTypeMap } from "@mui/material";
import {
  Add,
  ArrowBackRounded,
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  CheckOutlined,
  CheckRounded,
  ChevronRightRounded,
  ChevronLeftRounded,
  CloseOutlined,
  CloseRounded,
  ContentCopyOutlined,
  Create,
  DeleteOutlined,
  Download,
  Edit,
  ErrorOutlined,
  Folder,
  ImageOutlined,
  InfoOutlined,
  Logout,
  MenuRounded,
  MicOutlined,
  PauseRounded,
  Person,
  PlayArrowRounded,
  QuestionMarkOutlined,
  SearchOutlined,
  Stop,
  Upload,
  VisibilityOffOutlined,
  VisibilityOutlined,
  DarkMode,
  LightMode,
  AutoFixHigh,
  KeyboardVoice,
  ReplayRounded,
  RecordVoiceOver,
  Groups,
} from "@mui/icons-material";

import { memoize } from "../../utils/performance";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Style } from "../../utils/types";

const icons: Record<string, OverridableComponent<SvgIconTypeMap<{}, "svg">>> = {
  "arrow-back": ArrowBackRounded,
  "arrow-up": ArrowUpwardRounded,
  "arrow-down": ArrowDownwardRounded,
  "info-outline": InfoOutlined,
  "question-mark": QuestionMarkOutlined,
  mic: MicOutlined,
  "add-voice": KeyboardVoice,
  folder: Folder,
  image: ImageOutlined,
  stop: Stop,
  check: CheckRounded,
  info: InfoOutlined,
  "error-outline": ErrorOutlined,
  "delete-outline": DeleteOutlined,
  "content-copy-outline": ContentCopyOutlined,
  "checkmark-outline": CheckOutlined,
  "play-arrow": PlayArrowRounded,
  pause: PauseRounded,
  close: CloseOutlined,
  x: CloseRounded,
  menu: MenuRounded,
  "chevron-down": ChevronRightRounded,
  "chevron-right": ChevronRightRounded,
  "chevron-left": ChevronLeftRounded,
  person: Person,
  download: Download,
  search: SearchOutlined,
  upload: Upload,
  add: Add,
  "eye-outline": VisibilityOutlined,
  "eye-slash-outline": VisibilityOffOutlined,
  edit: Edit,
  create: Create,
  logout: Logout,
  moon: DarkMode,
  sun: LightMode,
  "magic-wand": AutoFixHigh,
  refresh: ReplayRounded,
  "voice-over": RecordVoiceOver,
  community: Groups,
};

const styles: Record<string, Style> = {
  "chevron-down": { transform: "rotate(90deg)" },
};

type IconProps = {
  name: string;
  size?: number;
  className?: string;
};

export const Icon = memoize(({ name, size = 24, className }: IconProps) => {
  const Component = icons[name] || QuestionMarkOutlined;

  return (
    <Component
      className={className}
      style={{
        fontSize: size,
        ...styles[name],
      }}
    />
  );
});
