import { WS_URL } from "../config";

export let webSocket: WebSocket;

export const initializeSocket = () => {
  webSocket = new WebSocket(WS_URL);

  const heartbeat = () => {
    if (webSocket.readyState === WebSocket.OPEN) {
      webSocket.send(JSON.stringify({ event: "heartbeat" }));
    }

    setTimeout(() => heartbeat(), 10000);
  };

  webSocket.onopen = () => {
    console.log("WebSocket connection established.");

    heartbeat();

    if (webSocket.readyState === WebSocket.OPEN) {
      webSocket.send(JSON.stringify({ event: "joinRoom", data: { roomId: "training-jobs" } }));
    }
  };

  webSocket.onmessage = (event) => {
    const parsedMessage = JSON.parse(event.data);

    switch (parsedMessage?.event) {
      case "story.updated":
        break;
      case "storyChunk.updated":
        break;
      case "heartbeat":
        console.log("Heartbeat received");
        break;
      default:
        console.log(`Unknown event: ${parsedMessage?.event}`);
        break;
    }
  };
};
