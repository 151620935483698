import { useEffect } from "react";

import { Button } from "../components/base/Button";
import { Text } from "../components/base/Text";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { useApiRequest } from "../hooks/useApiRequest";
import { AuthLayout } from "../components/auth/AuthLayout";
import { SignUpForm } from "../components/auth/SignUpForm";
import { encodeState, parseState } from "../utils/parse";
import { useSearchParam } from "../hooks/useSearchParam";
import { View } from "../components/base/View";

export const SignUpPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const state = parseState<{ id: string }>(useSearchParam("state"));
  const createAccountApiRequest = useApiRequest();

  const onSubmit = async (formData: { email?: string; password?: string; name?: string }) => {
    const { error } = await createAccountApiRequest.fetch("/v1/auth/signup", {
      method: "POST",
      body: {
        email: formData.email,
        password: formData.password,
        name: formData.name,
      },
    });

    if (!error) {
      await dispatch(sessionActions.signIn({ email: formData.email, password: formData.password }));

      navigation.navigate("/checkout", {
        state: encodeState({
          id: state?.id || "",
        }),
      });
    }
  };

  return (
    <AuthLayout
      title="Create Account"
      subtitle="Join Prim Voices and start creating"
      footer={
        <View className="text-center space-x-1">
          <Text variant="muted">Already have an account?</Text>
          <Button
            variant="ghost"
            type="basic"
            className="!p-0 font-medium"
            onClick={() => navigation.navigate("/signin")}
          >
            Sign in
          </Button>
        </View>
      }
    >
      <SignUpForm
        onSubmit={onSubmit}
        isLoading={createAccountApiRequest.isLoading}
        error={createAccountApiRequest.error?.message}
      />
    </AuthLayout>
  );
};
