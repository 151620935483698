import React from "react";
import { useLocation } from "react-router-dom";
import { clsx } from "clsx";

import { BaseHeader } from "./BaseHeader";

type AuthenticatedHeaderProps = {
  className?: string;
  onClickProfile: () => void;
};

export const AuthenticatedHeader = ({ className, onClickProfile }: AuthenticatedHeaderProps) => {
  const { pathname } = useLocation();

  return (
    <BaseHeader
      className={className}
      links={[
        { label: "Speech", to: "/create" },
        { label: "Voices", to: "/voices" },
      ]}
      onClickProfile={onClickProfile}
    />
  );
};
