import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { View } from "./View";
import { Style } from "../../utils/types";

type FormProps<T extends Record<string, unknown>> = {
  className?: string;
  onChange?: (formData: T) => void;
  children: React.ReactNode;
  data: T;
  style?: Style;
  gap?: "none" | "sm" | "md" | "lg";
};

export const Form = <T extends Record<string, unknown>>({ className, onChange, children, data, style, gap = "md" }: FormProps<T>) => {
  const [formData, setFormData] = useState<T>(data);

  const onChangeHandler = (key: keyof T) => (value: unknown) => {
    const newFormData = {
      ...formData,
      [key]: value,
    };

    setFormData(newFormData);
    onChange?.(newFormData);
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <View
      className={clsx(
        "w-full flex flex-col",
        {
          "gap-2": gap === "sm",
          "gap-4": gap === "md",
          "gap-6": gap === "lg",
        },
        className,
      )}
      style={style}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child;

        const childProps = child.props as { name?: keyof T };
        if (!childProps.name) return child;

        return React.cloneElement(child, {
          ...child.props,
          onChange: onChangeHandler(childProps.name),
          value: formData[childProps.name],
        });
      })}
    </View>
  );
};
