import clsx from "clsx";
import { FileUploader } from "react-drag-drop-files";

import { Text } from "./Text";
import { View } from "./View";
import { Icon } from "./Icon";

type FileFieldProps = {
  type?: "button" | "drag-drop";
  className?: string;
  fileTypes?: string[];
  onChange: (files: FileList) => void | Promise<void>;
  value?: FileList;
  size?: "sm" | "md" | "lg";
  error?: string;
};

export const FileField = ({ className, onChange, fileTypes, value, type = "drag-drop", size = "lg", error }: FileFieldProps) => {
  return (
    <View
      className={clsx(
        "relative w-full flex items-center justify-center",
        "rounded-lg transition-all duration-200",
        "border-2 border-dashed",
        {
          "h-32": size === "sm",
          "h-40": size === "md",
          "h-52": size === "lg",
          "border-gray-300 dark:border-gray-600": !error,
          "border-red-500 dark:border-red-400": error,
          "hover:border-brand dark:hover:border-brand-light": !error,
          "hover:border-red-400 dark:hover:border-red-300": error,
        },
        "bg-gray-50 dark:bg-gray-800/50",
        className,
      )}
    >
      <View
        className={clsx(
          "absolute flex flex-col items-center justify-center gap-2",
          size === "sm" && "flex-row text-sm",
          size === "md" && "flex-col text-base",
          size === "lg" && "flex-col text-lg",
        )}
      >
        {value && value.length > 0 ? (
          <View className="flex flex-col items-center gap-2">
            <Icon
              name="check"
              size={size === "lg" ? 32 : size === "md" ? 24 : 20}
              className="text-brand dark:text-brand-light"
            />
            <Text variant="brand">File Uploaded!</Text>
          </View>
        ) : type === "button" ? (
          <View className="flex items-center gap-2">
            <Icon
              name="upload"
              size={size === "lg" ? 24 : size === "md" ? 20 : 16}
              className="text-gray-400 dark:text-gray-500"
            />
            <Text variant="muted">Upload File</Text>
          </View>
        ) : (
          <>
            <Icon
              name="upload"
              size={size === "lg" ? 32 : size === "md" ? 24 : 20}
              className="text-gray-400 dark:text-gray-500"
            />
            <Text variant="muted">Drag & Drop Files</Text>
            <Text variant="muted">- or -</Text>
            <Text variant="muted">Click to Upload</Text>
          </>
        )}
      </View>
      <FileUploader
        classes="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        handleChange={onChange}
        name="file"
        types={fileTypes}
        multiple
      />
    </View>
  );
};
