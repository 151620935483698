import { useState, useEffect } from "react";
import { View } from "./View";
import { Text } from "./Text";
import clsx from "clsx";

interface Suggestion {
  label: string;
  description: string;
}

interface SuggestionBubblesProps {
  onSelect: (description: string) => void;
  className?: string;
}

const SUGGESTIONS = [
  {
    label: "Neutral & Informative",
    description: "Speak clearly and calmly, as if explaining to a curious listener.",
  },
  {
    label: "Warm & Inviting",
    description: "Use a friendly tone, like you're welcoming an old friend.",
  },
  {
    label: "Energetic & Enthusiastic",
    description: "Imagine you're sharing exciting news with a group of people.",
  },
  {
    label: "Calm & Reassuring",
    description: "Speak softly and gently, like comforting someone nervous.",
  },
  {
    label: "Authoritative & Confident",
    description: "Use a firm, decisive tone that commands attention.",
  },
  {
    label: "Playful & Cheerful",
    description: "Add a light, upbeat energy, like sharing a funny story.",
  },
  {
    label: "Dramatic & Intense",
    description: "Bring gravity to your tone, as if narrating a suspenseful moment.",
  },
  {
    label: "Whimsical & Dreamy",
    description: "Add a touch of wonder, like describing a magical place.",
  },
  {
    label: "Urgent & Serious",
    description: "Increase pace and intensity, as though giving critical instructions.",
  },
  {
    label: "Casual & Conversational",
    description: "Use a relaxed, informal tone, as if chatting with a friend.",
  },
];

export const SuggestionBubbles = ({ onSelect, className }: SuggestionBubblesProps) => {
  const [selectedSuggestions, setSelectedSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    // Randomly select 3 suggestions
    const shuffled = [...SUGGESTIONS].sort(() => 0.5 - Math.random());
    setSelectedSuggestions(shuffled.slice(0, 3));
  }, []);

  return (
    <View className={clsx("flex flex-wrap gap-2", className)}>
      {selectedSuggestions.map((suggestion, index) => (
        <button
          key={index}
          onClick={() => onSelect(suggestion.description)}
          className={clsx(
            "px-3 py-1.5 rounded-full text-sm transition-all duration-200",
            "bg-brand/10 dark:bg-brand/20 hover:bg-brand/20 dark:hover:bg-brand/30",
            "border border-brand/20 dark:border-brand/30",
            "text-brand dark:text-brand-light",
          )}
        >
          {suggestion.label}
        </button>
      ))}
    </View>
  );
};
