import { Outlet, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

import { View } from "../components/base/View";
import { AdminHeader } from "../components/layout/Header/AdminHeader";
import { RequireAuth } from "../components/auth/RequireAuth";

type AdminLayoutProps = {
  className?: string;
};

export const AdminLayout = ({ className }: AdminLayoutProps) => {
  const navigate = useNavigate();

  const onClickSignOut = () => {
    navigate("/signout");
  };

  const onClickProfile = () => {
    navigate("/profile");
  };

  return (
    <RequireAuth
      requireAdmin
      className={className}
    >
      <View className="min-h-screen bg-gray-50 dark:bg-gray-900">
        {/* Header */}
        <View className="sticky top-0 z-50">
          <AdminHeader
            onClickProfile={onClickProfile}
            onClickSignOut={onClickSignOut}
          />
        </View>

        {/* Main Content */}
        <View className="flex-1 min-h-[calc(100vh-73px)] bg-gray-50 dark:bg-gray-900">
          <Outlet />
        </View>
      </View>
    </RequireAuth>
  );
};
