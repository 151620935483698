import React from "react";
import { useLocation } from "react-router-dom";

import { BaseHeader } from "./BaseHeader";

type AdminHeaderProps = {
  className?: string;
  onClickProfile?: () => void;
  onClickSignOut?: () => void;
};

export const AdminHeader = ({ className, onClickProfile, onClickSignOut }: AdminHeaderProps) => {
  const { pathname } = useLocation();

  return (
    <BaseHeader
      className={className}
      title="Admin"
      showBrand={true}
      links={[
        { label: "Users", to: "/admin/users" },
        { label: "Public Voices", to: "/admin/publicVoices" },
        { label: "Training Jobs", to: "/admin/trainingJobs" },
      ]}
      onClickProfile={onClickProfile}
      onClickSignOut={onClickSignOut}
      isProfileActive={pathname === "/profile"}
    />
  );
};
