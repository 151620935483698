import { PublicVoice } from "./publicVoiceSlice";
import { createRestSlice } from "./restSlice";

export type Voice = PublicVoice & {
  userId: string;
  verified: boolean;
  isPublished?: boolean;
  baseVoiceId?: string;
  gender?: string;
  accent?: string;
  pitch?: string;
};

const voiceSlice = createRestSlice<Voice>("voice", "/v1/voices");

export const voiceReducer = voiceSlice.reducer;
export const voiceActions = voiceSlice.actions;
