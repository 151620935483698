import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { useAppSelector } from "../../redux";
import { useNavigation } from "../../hooks/useNavigation";
import { View } from "../base/View";
import { Spinner } from "../base/Spinner";

type RequireAuthProps = {
  children: React.ReactNode;
  requireAdmin?: boolean;
  className?: string;
};

export const RequireAuth = ({ children, requireAdmin = false, className }: RequireAuthProps) => {
  const navigation = useNavigation();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const user = useAppSelector((state) => state.session.user);
  const isLoading = useAppSelector((state) => state.session.isLoadingSession);

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        const params = new URLSearchParams();
        searchParams.forEach((value, key) => {
          params.append(key, value);
        });
        navigation.navigate(`/signin?redirect=${pathname}?${params.toString()}`);
      } else if (requireAdmin && !user.isAdmin) {
        navigation.navigate("/");
      }
    }
  }, [user, isLoading, requireAdmin]);

  if (isLoading) {
    return (
      <View className="flex items-center justify-center w-full h-full bg-white dark:bg-gray-900">
        <Spinner className="text-brand dark:text-brand-light w-8 h-8" />
      </View>
    );
  }

  if (!user || (requireAdmin && !user.isAdmin)) {
    return <View className={className} />;
  }

  return <>{children}</>;
};
