import { forwardRef } from "react";

interface ViewProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

export const View = forwardRef<HTMLDivElement, ViewProps>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={className}
      {...props}
    >
      {children}
    </div>
  );
});
