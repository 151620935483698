import { makeRequest } from "../../utils/request";
import { RestList } from "../../components/shared/RestList";
import { User } from "../../redux/reducers/userSlice";
import { View } from "../../components/base/View";

type AdminTrainingJobsPageProps = {
  className?: string;
};

export const AdminTrainingJobsPage = ({ className }: AdminTrainingJobsPageProps) => {
  const search = async (query: string) => {
    try {
      const { data } = await makeRequest<{ data: User[] }>(`/v1/trainingJobs/search?q=${query}`);
      return data.map((trainingJob) => trainingJob.id);
    } catch (error) {
      console.error("Error searching training jobs:", error);
    }
    return [];
  };

  return (
    <View className="h-full bg-gray-50 dark:bg-gray-900">
      <RestList
        className={className}
        entityName="trainingJob"
        search={search}
        fields={[
          {
            name: "id",
            label: "ID",
            readOnly: true,
          },
          {
            name: "jobName",
            label: "Job Name",
          },
          {
            name: "datasetName",
            label: "Dataset Name",
            visible: false,
          },
          {
            name: "status",
            label: "Status",
            readOnly: true,
          },
          {
            name: "epoch",
            label: "Epoch",
            readOnly: true,
            visible: false,
          },
          {
            name: "latestCheckpoint",
            label: "Latest Checkpoint",
            readOnly: true,
          },
          {
            name: "createdAt",
            label: "Created At",
            readOnly: true,
            visible: false,
          },
          {
            name: "completedAt",
            label: "Completed At",
            readOnly: true,
            visible: false,
          },
        ]}
      />
    </View>
  );
};
