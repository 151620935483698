import React, { useState } from "react";
import { View } from "./base/View";
import { Text } from "./base/Text";
import { Button } from "./base/Button";
import { InputField } from "./base/InputField";
import { FileField } from "./base/FileField";
import { CheckboxField } from "./base/CheckboxField";
import { useAppDispatch } from "../redux";
import { voiceActions } from "../redux/reducers/voiceSlice";
import { fileActions } from "../redux/reducers/fileSlice";

type CreateVoiceModalContentProps = {
  onClose: () => void;
  baseVoiceId?: string | null;
};

export const CreateVoiceModalContent = ({ onClose, baseVoiceId }: CreateVoiceModalContentProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [voiceName, setVoiceName] = useState("");
  const [description, setDescription] = useState("");
  const [voiceSampleFile, setVoiceSampleFile] = useState<FileList>();
  const [isVerified, setIsVerified] = useState(false);
  const [tags, setTags] = useState("");
  const [gender, setGender] = useState("all");
  const [accent, setAccent] = useState("all");
  const [pitch, setPitch] = useState("all");

  const onClickCreate = async () => {
    if (!voiceName || (!baseVoiceId && !voiceSampleFile) || !isVerified) return;

    setIsLoading(true);

    try {
      let sampleUrl = "";

      if (voiceSampleFile) {
        sampleUrl = await dispatch(
          fileActions.uploadFile({
            type: "audio",
            file: voiceSampleFile[0],
          }),
        ).unwrap();
      }

      await dispatch(
        voiceActions.create({
          name: voiceName,
          description,
          sampleUrl: sampleUrl || undefined,
          tags,
          gender: gender !== "all" ? gender : undefined,
          accent: accent !== "all" ? accent : undefined,
          pitch: pitch !== "all" ? pitch : undefined,
          verified: isVerified,
          baseVoiceId: baseVoiceId || undefined,
        }),
      ).unwrap();

      onClose();
    } catch (error) {
      console.error("Failed to create voice:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <View className="p-4 space-y-4">
      <View className="space-y-1.5">
        <Text variant="body">Voice Name</Text>
        <InputField
          value={voiceName}
          onChange={setVoiceName}
          placeholder="Enter voice name..."
          className="w-full"
        />
      </View>

      <View className="space-y-1.5">
        <Text variant="body">Description</Text>
        <InputField
          value={description}
          onChange={setDescription}
          placeholder="Enter voice description..."
          className="w-full"
          type="textarea"
        />
      </View>

      <View className="space-y-1.5">
        <Text variant="body">Tags</Text>
        <InputField
          value={tags}
          onChange={setTags}
          placeholder="Enter comma-separated tags..."
          className="w-full"
        />
        <Text
          variant="muted"
          className="text-xs"
        >
          Separate tags with commas (e.g. "british, male, deep")
        </Text>
      </View>

      <View className="grid grid-cols-3 gap-3">
        <View className="space-y-1.5">
          <Text variant="body">Gender</Text>
          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className="w-full p-1.5 rounded-md border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
          >
            <option value="all">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="neutral">Neutral</option>
          </select>
        </View>

        <View className="space-y-1.5">
          <Text variant="body">Accent</Text>
          <select
            value={accent}
            onChange={(e) => setAccent(e.target.value)}
            className="w-full p-1.5 rounded-md border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
          >
            <option value="all">All</option>
            <option value="american">American</option>
            <option value="british">British</option>
            <option value="australian">Australian</option>
          </select>
        </View>

        <View className="space-y-1.5">
          <Text variant="body">Pitch</Text>
          <select
            value={pitch}
            onChange={(e) => setPitch(e.target.value)}
            className="w-full p-1.5 rounded-md border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800"
          >
            <option value="all">All</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </View>
      </View>

      {!baseVoiceId && (
        <View className="space-y-1.5">
          <Text variant="body">Voice Sample</Text>
          <FileField
            value={voiceSampleFile}
            onChange={setVoiceSampleFile}
            fileTypes={["wav", "mp3", "m4a", "flac", "ogg"]}
            className="w-full"
          />
          <Text
            variant="muted"
            className="text-xs"
          >
            Supported formats: WAV, MP3, M4A, FLAC, OGG
          </Text>
        </View>
      )}

      <View className="flex flex-row items-start gap-2 bg-gray-50 dark:bg-gray-800 p-3 rounded-lg">
        <CheckboxField
          value={isVerified}
          onChange={setIsVerified}
          className="mt-1"
        />
        <Text
          variant="muted"
          className="text-xs"
        >
          I verify that I have the necessary rights and permissions to use this voice for generation purposes, and I accept responsibility for any misuse. By
          checking this box, I acknowledge that I have obtained proper consent from the voice owner or have the legal right to use this voice.
        </Text>
      </View>

      <View className="flex justify-end space-x-2 pt-2">
        <Button
          onClick={onClose}
          variant="ghost"
        >
          Cancel
        </Button>
        <Button
          onClick={onClickCreate}
          variant="default"
          isLoading={isLoading}
          isDisabled={!voiceName || (!baseVoiceId && !voiceSampleFile) || !isVerified}
        >
          Create Voice
        </Button>
      </View>
    </View>
  );
};
