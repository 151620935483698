import React from "react";
import { Button } from "../base/Button";
import { Icon } from "../base/Icon";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { Tooltip } from "../base/Tooltip";

type GenerationCardProps = {
  id: string;
  text: string;
  audioUrl: string;
  createdAt: string;
  voiceName: string;
  model?: string;
  notes?: string;
  isPlaying?: boolean;
  onPlay: (generationId: string, audioUrl: string) => void;
  onDownload: (audioUrl: string) => void;
  onGenerateWithConfig: () => void;
};

export const GenerationCard = ({
  id,
  text,
  audioUrl,
  createdAt,
  voiceName,
  model,
  notes,
  isPlaying,
  onPlay,
  onDownload,
  onGenerateWithConfig,
}: GenerationCardProps) => {
  return (
    <View className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4 border border-gray-200 dark:border-gray-600 h-full flex flex-col">
      <View className="flex-1">
        <Text
          variant="body"
          className="font-semibold line-clamp-2 mb-2"
        >
          {text}
        </Text>

        {notes && (
          <Text
            variant="muted"
            className="text-sm italic line-clamp-2 mb-2"
          >
            Director's Notes: {notes}
          </Text>
        )}

        <View className="flex flex-row items-center space-x-2 flex-wrap text-xs">
          <Text
            variant="muted"
            className="text-sm"
          >
            {new Date(createdAt).toLocaleString(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Text>
          <Text
            variant="muted"
            className="text-sm"
          >
            •
          </Text>
          <Text
            variant="muted"
            className="text-sm"
          >
            {voiceName}
          </Text>
          {model && (
            <>
              <Text
                variant="muted"
                className="text-sm"
              >
                •
              </Text>
              <Text
                variant="muted"
                className="text-sm"
              >
                {model}
              </Text>
            </>
          )}
        </View>
      </View>

      <View className="flex flex-row items-center space-x-2 mt-4 justify-end border-t border-gray-200 dark:border-gray-600 pt-4">
        <Button
          onClick={() => onPlay(id, audioUrl)}
          type="basic"
          variant="ghost"
          className="!w-8 !h-8 !rounded-full"
        >
          <Icon
            name={isPlaying ? "pause" : "play-arrow"}
            size={18}
          />
        </Button>
        <Button
          onClick={() => onDownload(audioUrl)}
          type="basic"
          variant="ghost"
          className="!w-8 !h-8 !rounded-full"
        >
          <Icon
            name="download"
            size={18}
          />
        </Button>
        <Tooltip content="Generate with same configuration">
          <Button
            onClick={onGenerateWithConfig}
            type="basic"
            variant="ghost"
            className="!w-8 !h-8 !rounded-full"
          >
            <Icon
              name="refresh"
              size={18}
            />
          </Button>
        </Tooltip>
      </View>
    </View>
  );
};
