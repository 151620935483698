import clsx from "clsx";
import { ReactNode } from "react";

import { View } from "./View";
import { Text } from "./Text";
import { Style } from "../../utils/types";

type FieldLabelProps = {
  className?: string;
  children: ReactNode;
  label?: ReactNode;
  error?: string;
  style?: Style;
};

export const FieldLabel = ({ className, children, label, error, style }: FieldLabelProps) => {
  return (
    <View
      className={clsx("w-full flex flex-col items-start justify-start relative", className)}
      style={style}
    >
      {label && <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">{label}</label>}
      {children}
      {error && <Text className="mt-1 text-sm text-red-500">{error}</Text>}
    </View>
  );
};
