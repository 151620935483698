import { CircularProgress } from "@mui/material";
import clsx from "clsx";

import { View } from "./View";
import { forwardRef } from "react";

type ButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseMove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  variant?: "default" | "secondary" | "outline" | "ghost" | "brand" | "icon";
  type?: "default" | "basic" | "primary";
  href?: string;
  style?: React.CSSProperties;
  iconLeft?: React.ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant = "default",
      isDisabled,
      isLoading,
      onClick = () => {},
      onMouseDown = () => {},
      onMouseMove = () => {},
      onMouseUp = () => {},
      style,
      type = "default",
      iconLeft,
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "relative flex items-center justify-center rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 dark:focus:ring-offset-gray-900",
          type !== "basic" && variant !== "icon" && "px-6 py-3 font-semibold",
          variant === "default" && "bg-brand text-white hover:bg-brand-dark shadow-lg",
          variant === "secondary" && "bg-brand/10 text-brand hover:bg-brand/20",
          variant === "outline" && "border border-brand/20 text-brand hover:bg-brand/10",
          variant === "ghost" && "text-brand hover:bg-brand/10",
          variant === "brand" && "bg-brand hover:bg-brand-dark text-white shadow-lg",
          variant === "icon" && "w-10 h-10 rounded-full bg-brand text-white hover:bg-brand-dark shadow-lg p-0",
          isDisabled && "opacity-50 cursor-not-allowed hover:bg-brand",
          className,
        )}
        style={style}
        onClick={!isLoading ? onClick : undefined}
        onMouseDown={!isLoading ? onMouseDown : undefined}
        onMouseMove={!isLoading ? onMouseMove : undefined}
        onMouseUp={!isLoading ? onMouseUp : undefined}
        disabled={isDisabled}
      >
        {type === "basic" || variant === "icon" ? (
          children
        ) : (
          <>
            {iconLeft && <span className="mr-2">{iconLeft}</span>}
            <View className={clsx("flex items-center justify-center select-none", isLoading ? "opacity-0" : "opacity-100")}>{children}</View>
          </>
        )}
        {isLoading && (
          <View className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-5 h-5">
            <CircularProgress
              className={clsx(
                "!w-full !h-full",
                variant === "default" && "!text-white",
                variant === "secondary" && "!text-brand",
                variant === "outline" && "!text-brand",
                variant === "ghost" && "!text-brand",
                variant === "brand" && "!text-white",
                variant === "icon" && "!text-white",
              )}
              size="small"
            />
          </View>
        )}
      </button>
    );
  },
);
