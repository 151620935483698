import { useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import { generationActions, generationReducer } from "./reducers/generationSlice";
import { publicVoiceActions, publicVoiceReducer } from "./reducers/publicVoiceSlice";
import { sessionReducer } from "./reducers/sessionSlice";
import { userActions, userReducer } from "./reducers/userSlice";
import { voiceActions, voiceReducer } from "./reducers/voiceSlice";
import { fileReducer } from "./reducers/fileSlice";
import { trainingJobActions, trainingJobReducer } from "./reducers/trainingJobSlice";

export type RootState = ReturnType<typeof store.getState>;
export type EntityState = Omit<RootState, "session" | "file">;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const store = configureStore({
  reducer: {
    generation: generationReducer,
    publicVoice: publicVoiceReducer,
    session: sessionReducer,
    user: userReducer,
    voice: voiceReducer,
    file: fileReducer,
    trainingJob: trainingJobReducer,
  },
});

export const getActionsByName = (entityName: keyof EntityState) => {
  switch (entityName) {
    case "generation":
      return generationActions;
    case "publicVoice":
      return publicVoiceActions;
    case "user":
      return userActions;
    case "voice":
      return voiceActions;
    case "trainingJob":
      return trainingJobActions;
    default:
      throw new Error(`Unknown entity name: ${entityName}`);
  }
};
