import { CircularProgress } from "@mui/material";
import clsx from "clsx";

type SpinnerProps = {
  className?: string;
  size?: number;
  variant?: "default" | "brand";
};

export const Spinner = ({ className, size = 24, variant = "default" }: SpinnerProps) => {
  return (
    <CircularProgress
      className={clsx(
        "transition-colors duration-200",
        variant === "default" && "text-gray-900 dark:text-gray-100",
        variant === "brand" && "text-brand dark:text-brand-light",
        className,
      )}
      size={size}
    />
  );
};
