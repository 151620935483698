import React from "react";
import { clsx } from "clsx";
import { useLocation } from "react-router-dom";

import { Button } from "../../base/Button";
import { Icon } from "../../base/Icon";
import { Link } from "../../base/Link";
import { ThemeToggle } from "../../base/ThemeToggle";
import { View } from "../../base/View";
import { Text } from "../../base/Text";

export type HeaderLink = {
  label: string;
  to: string;
};

type BaseHeaderProps = {
  className?: string;
  links?: HeaderLink[];
  onClickProfile?: () => void;
  onClickSignOut?: () => void;
  isProfileActive?: boolean;
  title?: string;
  showBrand?: boolean;
};

export const BaseHeader = ({ className, links = [], onClickProfile, onClickSignOut, isProfileActive, title, showBrand = true }: BaseHeaderProps) => {
  const { pathname } = useLocation();

  const isLinkActive = (linkPath: string) => {
    // Exact match for root path
    if (linkPath === "/" && pathname === "/") return true;
    // For other paths, check if the current path starts with the link path
    return linkPath !== "/" && pathname.startsWith(linkPath);
  };

  return (
    <View className={clsx("sticky top-0 z-50 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm border-b border-gray-200 dark:border-gray-800", className)}>
      <View className="w-full px-4">
        <View className="flex justify-between items-center py-4">
          <View className="flex items-center">
            {showBrand && (
              <Link
                to="/"
                className="!text-xl md:!text-2xl !font-bold !text-brand dark:!text-brand-light hover:!text-brand dark:hover:!text-brand-light"
              >
                Prim Voices
              </Link>
            )}
            {title && (
              <Text className="ml-2 text-lg font-semibold bg-gradient-to-b from-black to-gray-500 dark:from-white dark:to-gray-500 bg-clip-text text-transparent">
                {title}
              </Text>
            )}
          </View>

          {links.length > 0 && (
            <View className="flex space-x-4 md:space-x-8">
              {links.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className={clsx(
                    "transition-all duration-200 pb-1 border-b-2 text-sm md:text-base",
                    isLinkActive(link.to)
                      ? "text-brand dark:text-brand-light font-medium border-brand dark:border-brand-light"
                      : "text-gray-600 dark:text-gray-300 hover:text-brand dark:hover:text-brand-light border-transparent hover:border-brand/30 dark:hover:border-brand-light/30",
                  )}
                >
                  {link.label}
                </Link>
              ))}
            </View>
          )}

          <View className="flex items-center gap-4">
            <ThemeToggle />

            {onClickProfile && (
              <Button
                variant="icon"
                onClick={onClickProfile}
              >
                <Icon
                  name="person"
                  size={20}
                  className="text-current"
                />
              </Button>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};
