import clsx from "clsx";

type TextProps = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent) => void;
  variant?: "body" | "brand" | "muted" | "error" | "success" | "warning";
  as?: keyof JSX.IntrinsicElements;
};

export const Text = ({ className, children, style, onClick, variant = "body", as: Component = "span" }: TextProps) => {
  return (
    <Component
      className={clsx(
        "transition-colors duration-200",
        {
          "text-gray-900 dark:text-gray-100": variant === "body",
          "text-brand dark:text-brand-light": variant === "brand",
          "text-gray-500 dark:text-gray-400": variant === "muted",
          "text-red-600 dark:text-red-400": variant === "error",
          "text-green-600 dark:text-green-400": variant === "success",
          "text-yellow-600 dark:text-yellow-400": variant === "warning",
        },
        className,
      )}
      style={style}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};
