import React, { useState } from "react";

import { Button } from "../components/base/Button";
import { Form } from "../components/base/Form";
import { InputField } from "../components/base/InputField";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { Text } from "../components/base/Text";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { View } from "../components/base/View";
import { Card } from "../components/base/Card";

type FormData = {
  email: string;
};

export const ForgotPasswordPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoading);
  const [formData, setFormData] = useState<FormData>({ email: "" });
  const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);

  const onChangeFormData = (newFormData: Record<string, unknown>) => {
    setFormData(newFormData as FormData);
  };

  const onClickContinue = async () => {
    if (resetPasswordSent) {
      navigation.goBack();
    } else {
      await dispatch(sessionActions.resetPassword(formData));
      setResetPasswordSent(true);
    }
  };

  return (
    <View className="w-full min-h-screen flex items-center justify-center p-4">
      <View className="w-full max-w-md space-y-8">
        {/* Header */}
        <View className="text-center space-y-2">
          <Text
            variant="brand"
            className="text-4xl font-bold"
          >
            Reset Password
          </Text>
          <Text
            variant="muted"
            className="text-lg"
          >
            {resetPasswordSent ? "Check your email for reset instructions" : "Enter your email to reset your password"}
          </Text>
        </View>

        {/* Reset Form */}
        <Card className="p-6 space-y-6">
          <Button
            variant="ghost"
            type="basic"
            onClick={() => navigation.goBack()}
            className="!p-0 mb-4 text-brand dark:text-brand-light hover:opacity-80"
          >
            ← Back to Sign In
          </Button>

          {resetPasswordSent ? (
            <Text variant="muted">We've sent you a link to reset your password if an account exists with that email address.</Text>
          ) : (
            <Form
              data={formData}
              onChange={onChangeFormData}
            >
              <InputField
                label="Email Address"
                name="email"
                placeholder="Enter your email"
              />
            </Form>
          )}

          <Button
            onClick={onClickContinue}
            isDisabled={!formData.email}
            isLoading={isLoading}
          >
            {resetPasswordSent ? "Back to Sign In" : "Send Reset Link"}
          </Button>
        </Card>
      </View>
    </View>
  );
};
