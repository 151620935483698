import clsx from "clsx";

import { View } from "./View";

type CardProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  hover?: boolean;
  variant?: "default" | "elevated";
};

export const Card = ({ children, className, style, hover, variant = "default" }: CardProps) => {
  return (
    <View
      className={clsx(
        "flex flex-col rounded-xl transition-all duration-200",
        "bg-white dark:bg-gray-800",
        "border border-gray-200 dark:border-gray-700",
        variant === "elevated" && "shadow-lg",
        hover && ["hover:border-brand/50 dark:hover:border-brand/50", variant === "elevated" && "hover:shadow-xl"],
        className,
      )}
      style={style}
    >
      {children}
    </View>
  );
};
