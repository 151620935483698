import clsx from "clsx";
import { useState, useEffect, useRef } from "react";

import { FieldLabel } from "./FieldLabel";
import { Style } from "../../utils/types";
import { View } from "./View";
import { Icon } from "./Icon";
import { Text } from "./Text";

type SearchFieldProps = {
  name?: string;
  options: Array<{
    label: string;
    value: string;
    color?: string;
    previewUrl?: string;
  }>;
  value?: string;
  onChange?: (value: string) => void;
  onClickAddOption?: () => void;
  className?: string;
  label?: string;
  placeholder?: string;
  style?: Style;
  showAddOption?: boolean;
  onChangeSearchQuery?: (value: string) => void;
  error?: string;
};

export const SearchField = ({
  name,
  options = [],
  value,
  onChange = () => {},
  onClickAddOption,
  showAddOption,
  className,
  label,
  placeholder,
  style,
  onChangeSearchQuery = () => {},
  error,
}: SearchFieldProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [playingUrl, setPlayingUrl] = useState<string | null>(null);
  const searchFieldRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchFieldRef.current && !searchFieldRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onClickAddOptionInternal = () => {
    setIsOpen(false);
    onClickAddOption?.();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setIsOpen(true);
    onChangeSearchQuery(e.target.value);
  };

  const onClickOptionHandler = (value: string) => {
    onChange(value);
    setIsOpen(false);
    setSearch("");
  };

  const onClickPreviewHandler = (url?: string, e?: React.MouseEvent) => {
    e?.stopPropagation();
    if (!url) return;

    if (audio && playingUrl === url) {
      audio.pause();
      setAudio(null);
      setPlayingUrl(null);
      return;
    }

    if (audio) {
      audio.pause();
    }

    const newAudio = new Audio(url);
    newAudio.play();
    newAudio.addEventListener("ended", () => {
      setPlayingUrl(null);
      setAudio(null);
    });
    setAudio(newAudio);
    setPlayingUrl(url);
  };

  const selectedOption = options.find((opt) => opt.value === value);

  return (
    <FieldLabel
      label={label}
      error={error}
      className={clsx("relative", label ? "max-h-[86px]" : "max-h-[64px]", "overflow-visible z-50")}
    >
      <View
        ref={searchFieldRef}
        className="relative w-full"
      >
        <input
          value={isOpen ? search : selectedOption?.label || ""}
          onChange={onChangeInput}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          style={style}
          className={clsx(
            "w-full px-4 pr-10 py-2 rounded-lg",
            "transition-colors duration-200",
            "border",
            {
              "border-gray-300 dark:border-gray-700": !error,
              "border-red-500 dark:border-red-400": error,
            },
            "text-gray-900 dark:text-gray-100",
            "bg-white dark:bg-gray-800",
            "placeholder:text-gray-400 dark:placeholder:text-gray-500",
            "focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 dark:focus:ring-offset-gray-900",
            !error && "hover:border-brand/50 dark:hover:border-brand/50",
            error && "hover:border-red-400 dark:hover:border-red-300",
            className,
          )}
        />
        <View className="absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none">
          <Icon
            name={isOpen ? "chevron-up" : "chevron-down"}
            size={20}
            className={clsx("transition-colors duration-200", error ? "text-red-500 dark:text-red-400" : "text-gray-400 dark:text-gray-500")}
          />
        </View>

        {options.length > 0 && isOpen && (
          <View
            className={clsx(
              "absolute z-30 w-full max-h-[250px] overflow-y-auto",
              "bg-white dark:bg-gray-800",
              "border rounded-lg",
              error ? "border-red-500 dark:border-red-400" : "border-brand/50 dark:border-brand/50",
            )}
          >
            {showAddOption && (
              <View
                onClick={onClickAddOptionInternal}
                className={clsx(
                  "w-full flex items-center justify-between p-3",
                  "hover:bg-gray-50 dark:hover:bg-gray-700/50",
                  "border-b cursor-pointer",
                  error ? "border-red-500 dark:border-red-400" : "border-gray-200 dark:border-gray-700",
                )}
              >
                <View className="flex items-center gap-3">
                  <View className="w-6 h-6 rounded-full bg-gradient-to-br from-gray-400 to-gray-600" />
                  <Text className="text-gray-900 dark:text-gray-100">Add New Voice</Text>
                </View>
                <Icon
                  name="add"
                  size={20}
                  className="text-brand dark:text-brand-light"
                />
              </View>
            )}
            {filteredOptions.length > 0 &&
              filteredOptions.map((option, index) => (
                <View
                  key={option.value}
                  onClick={() => onClickOptionHandler(option.value)}
                  className={clsx(
                    "w-full flex items-center justify-between p-3",
                    "cursor-pointer transition-colors duration-150",
                    "hover:bg-gray-50 dark:hover:bg-gray-700/50",
                    value === option.value && "bg-gray-100 dark:bg-gray-700",
                    index !== 0 && "border-t",
                    error ? "border-red-500 dark:border-red-400" : "border-gray-200 dark:border-gray-700",
                  )}
                >
                  <View className="flex items-center gap-3">
                    <View
                      className="w-6 h-6 rounded-full"
                      style={{
                        background: option.color || "linear-gradient(135deg, #6366f1, #8b5cf6)",
                      }}
                    />
                    <Text className="text-gray-900 dark:text-gray-100">{option.label}</Text>
                  </View>
                  {option.previewUrl && (
                    <View
                      className={clsx(
                        "w-8 h-8 rounded-lg flex items-center justify-center",
                        "hover:bg-gray-100 dark:hover:bg-gray-700",
                        playingUrl === option.previewUrl && "bg-brand/10 dark:bg-brand/20",
                      )}
                      onClick={(event) => onClickPreviewHandler(option.previewUrl, event)}
                    >
                      <Icon
                        name={playingUrl === option.previewUrl ? "pause" : "play-arrow"}
                        size={20}
                        className="text-brand dark:text-brand-light"
                      />
                    </View>
                  )}
                </View>
              ))}
          </View>
        )}
      </View>
    </FieldLabel>
  );
};
