import { makeRequest } from "../../utils/request";
import { RestList } from "../../components/shared/RestList";
import { User } from "../../redux/reducers/userSlice";
import { View } from "../../components/base/View";

type AdminUsersPageProps = {
  className?: string;
};

export const AdminUsersPage = ({ className }: AdminUsersPageProps) => {
  const search = async (query: string) => {
    try {
      const { data } = await makeRequest<{ data: User[] }>(`/v1/users/search?q=${query}`);
      return data.map((user) => user.id);
    } catch (error) {
      console.error("Error searching users:", error);
    }
    return [];
  };

  return (
    <View className="h-full bg-gray-50 dark:bg-gray-900">
      <RestList
        className={className}
        entityName="user"
        search={search}
        fields={[
          {
            name: "id",
            label: "ID",
            readOnly: true,
          },
          {
            name: "name",
            label: "Name",
          },
          {
            name: "email",
            label: "Email",
          },
          {
            name: "isAdmin",
            label: "Admin",
            type: "checkbox",
          },
        ]}
      />
    </View>
  );
};
