import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Button } from "../base/Button";
import { Form } from "../base/Form";
import { InputField } from "../base/InputField";
import { View } from "../base/View";
import { Text } from "../base/Text";
import { CheckboxField } from "../base/CheckboxField";
import { ProgressBar } from "../shared/ProgressBar";
import { useApiRequest } from "../../hooks/useApiRequest";
import { openUrl } from "../../utils/linking";

type SignUpFormData = {
  email?: string;
  password?: string;
  confirmPassword?: string;
  name?: string;
};

type SignUpFormProps = {
  onSubmit: (data: SignUpFormData) => void;
  isLoading?: boolean;
  error?: string;
};

export const SignUpForm = ({ onSubmit, isLoading, error }: SignUpFormProps) => {
  const [formData, setFormData] = useState<SignUpFormData>({});
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const passwordStrengthRequest = useApiRequest<{ strength: string; score: number }>();

  const onChangePassword = useDebouncedCallback((password) => {
    passwordStrengthRequest.fetch("/v1/auth/strength", {
      method: "POST",
      body: { password },
    });
  }, 300);

  const onChangeFormData = (newFormData: SignUpFormData) => {
    setFormData(newFormData);

    if (!passwordStrengthRequest.isLoading && newFormData.password) {
      onChangePassword(newFormData.password);
    }

    if (error) {
      passwordStrengthRequest.clear();
    }
  };

  const passwordError = formData.confirmPassword && formData.password !== formData.confirmPassword ? "Passwords don't match" : undefined;

  return (
    <View className="space-y-6">
      <Form
        data={formData}
        onChange={onChangeFormData}
      >
        <View className="space-y-4">
          <InputField
            label="Name"
            name="name"
            placeholder="Enter your name"
            error={error}
          />
          <InputField
            label="Email"
            name="email"
            placeholder="Enter your email"
            error={error}
          />
          <InputField
            label="Password"
            name="password"
            type="password"
            placeholder="Create a password"
            error={passwordError || error}
          />
          <InputField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            placeholder="Confirm your password"
            error={passwordError || error}
          />
        </View>
      </Form>

      {/* Password Strength */}
      <View className="space-y-2">
        {!passwordStrengthRequest.data?.strength || (formData.password?.length || 0) < 10 ? (
          <Text
            variant="muted"
            className="text-sm"
          >
            Password must be at least 10 characters long and include at least one number or one symbol.
          </Text>
        ) : passwordStrengthRequest.data ? (
          <>
            <ProgressBar
              variant={
                passwordStrengthRequest.data?.strength === "weak" ? "error" : passwordStrengthRequest.data?.strength === "medium" ? "warning" : "success"
              }
              progress={(passwordStrengthRequest.data?.score || 0) * 25}
            />
            <Text
              variant={passwordStrengthRequest.data.strength === "strong" ? "success" : "warning"}
              className="text-sm"
            >
              {passwordStrengthRequest.data.strength === "strong" ? "Strong Password" : "Please Strengthen Password"}
            </Text>
          </>
        ) : null}
      </View>

      {/* Terms and Conditions */}
      <View className="flex items-start gap-3">
        <CheckboxField
          value={agreedToTerms}
          onChange={(newValue) => setAgreedToTerms(newValue)}
          className="mt-1 !w-4 !h-4 rounded border-gray-300 dark:border-gray-700 text-brand focus:ring-brand dark:focus:ring-brand-light"
        />
        <Text
          variant="muted"
          className="text-sm"
        >
          By creating an account you agree to our{" "}
          <Button
            type="basic"
            variant="ghost"
            className="!p-0 text-brand dark:text-brand-light hover:underline"
            onClick={() => openUrl(`${window.location.origin}/terms.pdf`)}
          >
            Terms of Service
          </Button>{" "}
          and{" "}
          <Button
            type="basic"
            variant="ghost"
            className="!p-0 text-brand dark:text-brand-light hover:underline"
            onClick={() => openUrl(`${window.location.origin}/privacy.pdf`)}
          >
            Privacy Policy
          </Button>
        </Text>
      </View>

      <Button
        onClick={() => onSubmit(formData)}
        isLoading={isLoading}
        isDisabled={
          !formData.email ||
          !formData.password ||
          !formData.confirmPassword ||
          formData.password !== formData.confirmPassword ||
          passwordStrengthRequest.data?.strength !== "strong" ||
          !agreedToTerms
        }
      >
        Create Account
      </Button>
    </View>
  );
};
