import { useEffect } from "react";

import { Button } from "../components/base/Button";
import { Text } from "../components/base/Text";
import { sessionActions } from "../redux/reducers/sessionSlice";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigation } from "../hooks/useNavigation";
import { AuthLayout } from "../components/auth/AuthLayout";
import { SignInForm } from "../components/auth/SignInForm";
import { View } from "../components/base/View";

export const SignInPage = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.session.isLoading);
  const error = useAppSelector((state) => state.session.error);
  const user = useAppSelector((state) => state.session.user);

  const onSubmit = (formData: { email: string; password: string }) => {
    dispatch(sessionActions.signIn(formData));
  };

  useEffect(() => {
    if (user) {
      navigation.navigate("/profile");
    }
  }, [user]);

  return (
    <AuthLayout
      title="Welcome Back"
      subtitle="Sign in to continue to Prim Voices"
      footer={
        <>
          <View className="flex items-center space-x-1">
            <Text variant="muted">Don't have an account?</Text>
            <Button
              variant="ghost"
              type="basic"
              className="!p-0 font-medium text-brand dark:text-brand-light hover:opacity-80"
              onClick={() => navigation.navigate("/signup")}
            >
              Sign up
            </Button>
          </View>

          <Button
            variant="ghost"
            type="basic"
            className="!p-0 text-sm text-gray-500 dark:text-gray-400 hover:text-brand dark:hover:text-brand-light"
            onClick={() => navigation.navigate("/forgot-password")}
          >
            Forgot your password?
          </Button>
        </>
      }
    >
      <SignInForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        error={error ? String(error) : undefined}
      />
    </AuthLayout>
  );
};
