import clsx from "clsx";

import { View } from "../base/View";

type ProgressBarProps = {
  className?: string;
  progress?: number;
  variant?: "brand" | "success" | "warning" | "error";
};

export const ProgressBar = ({ className, variant = "brand", progress = 0 }: ProgressBarProps) => {
  return (
    <View className={clsx("w-full h-2 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden", className)}>
      <View
        className={clsx("h-full rounded-full transition-all duration-200", {
          "bg-brand": variant === "brand",
          "bg-green-500 dark:bg-green-400": variant === "success",
          "bg-yellow-500 dark:bg-yellow-400": variant === "warning",
          "bg-red-500 dark:bg-red-400": variant === "error",
        })}
        style={{
          width: `${Math.min(Math.max(0, progress), 100)}%` || "0%",
        }}
      />
    </View>
  );
};
