import clsx from "clsx";
import { Dialog as HeadlessDialog } from "@headlessui/react";

import { View } from "./View";
import { Text } from "./Text";
import { Button } from "./Button";

type DialogProps = {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  size?: "sm" | "md" | "lg";
};

export const Dialog = ({ children, className, isOpen, onClose, title, size = "md" }: DialogProps) => {
  return (
    <HeadlessDialog
      open={isOpen}
      onClose={onClose}
      className="relative z-50"
    >
      <div
        className="fixed inset-0 bg-black/30 backdrop-blur-sm"
        aria-hidden="true"
      />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <HeadlessDialog.Panel
          className={clsx(
            "w-full rounded-xl",
            "bg-white dark:bg-gray-800",
            "shadow-xl border border-gray-200 dark:border-gray-700",
            {
              "max-w-sm": size === "sm",
              "max-w-md": size === "md",
              "max-w-lg": size === "lg",
            },
            className,
          )}
        >
          {title && (
            <View className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
              <HeadlessDialog.Title>
                <Text className="text-lg font-semibold">{title}</Text>
              </HeadlessDialog.Title>
              <Button
                type="basic"
                variant="ghost"
                className="!p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                onClick={onClose}
              >
                <View className="w-6 h-6 flex items-center justify-center">
                  <Text variant="muted">×</Text>
                </View>
              </Button>
            </View>
          )}
          {children}
        </HeadlessDialog.Panel>
      </div>
    </HeadlessDialog>
  );
};
