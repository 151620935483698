import React from "react";
import { Button } from "../base/Button";
import { Text } from "../base/Text";
import { View } from "../base/View";
import { GenerationCard } from "./GenerationCard";
import clsx from "clsx";

type Generation = {
  id: string;
  text: string;
  audioUrl: string;
  createdAt: string;
  voiceName: string;
  voiceId: string;
  model?: string;
  notes?: string;
};

type GenerationListProps = {
  generations: Generation[];
  onCreateNew: () => void;
  onPlayGeneration: (generationId: string, audioUrl: string) => void;
  onDownload: (audioUrl: string) => void;
  onGenerateWithConfig: (generation: Generation) => void;
  isPlayingGenerationId?: string;
  className?: string;
  maxDisplayCount?: number;
  variant?: "list" | "grid";
  title?: string;
};

export const GenerationList = ({
  generations,
  onCreateNew,
  onPlayGeneration,
  onDownload,
  onGenerateWithConfig,
  isPlayingGenerationId,
  className,
  maxDisplayCount = 10,
  variant = "list",
  title,
}: GenerationListProps) => {
  const sortedGenerations = [...generations].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).slice(0, maxDisplayCount);

  return (
    <View className={`bg-white dark:bg-gray-800 ${className}`}>
      {title && (
        <View className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
          <Text
            variant="brand"
            className="text-2xl font-bold"
          >
            {title}
          </Text>
          <Button
            onClick={onCreateNew}
            variant="default"
          >
            Create New
          </Button>
        </View>
      )}

      {!generations.length ? (
        <Text
          variant="muted"
          className="text-center py-8"
        >
          No generations yet
        </Text>
      ) : (
        <View className={clsx("p-4", variant === "grid" ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" : "flex flex-col gap-4")}>
          {sortedGenerations.map((generation) => (
            <GenerationCard
              key={generation.id}
              id={generation.id}
              text={generation.text}
              audioUrl={generation.audioUrl}
              createdAt={generation.createdAt}
              voiceName={generation.voiceName}
              model={generation.model}
              notes={generation.notes}
              isPlaying={isPlayingGenerationId === generation.id}
              onPlay={onPlayGeneration}
              onDownload={onDownload}
              onGenerateWithConfig={() => onGenerateWithConfig(generation)}
            />
          ))}
        </View>
      )}
    </View>
  );
};
