import React from "react";
import { clsx } from "clsx";
import { Link as RouterLink } from "react-router-dom";

type LinkProps = {
  to: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const Link = ({ to, children, className, onClick }: LinkProps) => {
  return (
    <RouterLink
      to={to}
      onClick={onClick}
      className={clsx("text-gray-600 dark:text-gray-300 hover:text-brand dark:hover:text-brand-light transition-colors", className)}
    >
      {children}
    </RouterLink>
  );
};
