import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { View } from "../components/base/View";
import { Text } from "../components/base/Text";
import { Spinner } from "../components/base/Spinner";
import { useNavigation } from "../hooks/useNavigation";

type RedirectPageProps = {
  className?: string;
  path: string;
};

export const RedirectPage = ({ className, path }: RedirectPageProps) => {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.navigate(path);
  }, []);

  return (
    <View className={`min-h-screen bg-white dark:bg-gray-900 ${className}`}>
      <View className="w-full max-w-5xl mx-auto p-8 flex flex-col items-center justify-center min-h-screen space-y-6">
        <Spinner className="w-12 h-12 text-brand dark:text-brand-light" />
        <Text
          variant="muted"
          className="text-lg text-center"
        >
          Redirecting...
        </Text>
      </View>
    </View>
  );
};
